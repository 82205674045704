import React, {
    useState,
    useEffect
} from 'react';
import Panel from "./Panel";
import styles from "./ModelPanel.module.css";

import { observer } from "mobx-react";
import { useStores } from '../stores/context'
import { Op } from "../stores/OpStore";

import { DEFAULT_TECHNIQUES } from "../Constants";
import OpDiagram from "../diagrams/OpDiagram";
import ModelDiagram from "../diagrams/ModelDiagram";
import useContainerDimensions from "../../../../hooks/useContainerDimensions";

import TechniqueChooser from "../../../../components/TechniqueChooser";
import { useTechniqueScope } from "../../../../hooks/useTechniqueScope";
import textStyles from "../../../../components/TextStyles.module.css";

import ListChooser from "../../../../components/controls/ListChooser";
import { techniqueTarget, chooserTypes } from "../techniques/Technique";

import ScrollPanel, { updateScrollPositionProps } from "../../../../components/ScrollPanel";
import { Model } from '../stores/ModelStore';


type ModelPanelProps = {
    baseUrl: string,
    scopedState: any,
    opened: boolean,
}
const ModelPanel = observer(({ baseUrl, scopedState, opened }: ModelPanelProps) => {

    const { modelViewStore, microscopeStore, modelTechniqueStore } = useStores();
    const { model, op } = microscopeStore;

    const urlParts = baseUrl.split("/");
    const closeUrl = urlParts.slice(0, -1).join("/");

    return (
        <>
            {model ? (
                <Panel
                    opened={opened}
                    openUrl={baseUrl}
                    closeUrl={closeUrl}
                    onStateChange={() => { microscopeStore.$measure(Date.now()) }}
                    showSidebarWhenOpened={true}
                    title={model.title}
                    sidebar={<ModelSidebar baseUrl={baseUrl} opened={opened} />}
                    main={<ModelMain baseUrl={baseUrl} opened={opened} />}
                    settings={
                        <div>
                            <p>
                                {model.description}
                            </p>
                            <hr />
                            <div>
                                <h4>Technique</h4>
                                <ListChooser options={modelTechniqueStore.techniqueOptions} />
                                <p className={textStyles.caption}><modelTechniqueStore.technique.Description /></p>
                            </div>
                            <hr />
                            {modelTechniqueStore.technique.params.length ? (
                                <>
                                    <div>
                                        <h4>Params</h4>
                                        {modelTechniqueStore.technique.params.map((param) => {
                                            return (
                                                <>
                                                    {(param.suppressDisplay && param.suppressDisplay.includes(techniqueTarget.model)) ?
                                                        null : (
                                                            <>
                                                                <h5>{param.title}</h5>
                                                                <ListChooser options={param.options} type={param.chooserType ? param.chooserType : chooserTypes.radio} />
                                                            </>
                                                        )}
                                                </>
                                            )
                                        })}
                                    </div>
                                    <hr />
                                </>
                            ) : null}
                            <div>
                                <h4>View</h4>
                                <h5>Image Size</h5>
                                <ListChooser options={modelViewStore.thumbnailSizeOptions} type={chooserTypes.slider} />
                                <h5>Description Width</h5>
                                <ListChooser options={modelViewStore.descriptionWidthOptions} type={chooserTypes.slider} />
                            </div>
                        </div>
                    }
                />
            ) : null}
        </>
    )
});
export default ModelPanel;

type ModelSidebarProps = {
    baseUrl: string,
    opened: boolean,
}
const ModelSidebar = observer(({ baseUrl, opened }: ModelSidebarProps) => {
    const { microscopeStore } = useStores();
    const op = microscopeStore.op;
    return (
        <>
            {microscopeStore.model ? (
                <div className={styles.opList}>
                    <OpDiagram
                        path={baseUrl}
                        model={microscopeStore.model}
                        opId={op ? op.id : null}
                        opened={opened}
                    />
                    {opened ? (
                        <SidebarScrollIndicator />
                    ) : null}
                </div>
            ) : null}
        </>
    );
})

const SidebarScrollIndicator = observer(({ }) => {
    const { modelViewStore } = useStores();
    const top = modelViewStore.scrollTopPercentage * 100;
    const height = modelViewStore.scrollFrameHeightPercentage * 100;
    return (
        <div style={{ pointerEvents: "none", borderRadius: "8px 0 0 8px", position: "absolute", background: "rgba(0, 0, 0, 0.05)", top: `${top}%`, height: `${height}%`, left: 16, right: 0 }}></div>

    )
});

type ModelMainProps = {
    baseUrl: string,
    opened: boolean,
}
const ModelMain = observer(({ baseUrl, opened }: ModelMainProps) => {

    const { modelViewStore, microscopeStore } = useStores();
    const { model } = microscopeStore;

    const [containerRef, containerDimensions] = useContainerDimensions([microscopeStore.model, modelViewStore.scrollContentHeight, opened, microscopeStore.measure]);

    useEffect(() => {
        if (containerDimensions) {
            modelViewStore.$scrollFrameHeight(containerDimensions.height);
        }
    }, [containerDimensions]);

    function scrollUpdate({ scrollTop }: updateScrollPositionProps) {
        modelViewStore.$scrollTop(scrollTop);
    }

    return (
        <div style={{ flexGrow: 1, flexBasis: 0 }} ref={containerRef}>
            {model && containerDimensions ? (
                <ScrollPanel
                    updateScrollPosition={scrollUpdate}
                    height={containerDimensions.height}
                    contentHeight={modelViewStore.scrollContentHeight}
                >
                    <ModelDiagram
                        matchUrl={baseUrl}
                        opSize={modelViewStore.thumbnailSize}
                        setDiagramHeight={modelViewStore.$scrollContentHeight}
                        descriptionWidth={modelViewStore.descriptionWidth}
                    />
                </ScrollPanel>
            ) : null}
        </div>);

});




