import React from 'react';

export default function Close() {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">

            <line x1="28.5" y1="3.5" x2="3" y2="29" />
            <line x1="3" y1="3.5" x2="28.5" y2="29" />
        </svg>
    );
}