import React, {
    useEffect, useState
} from 'react';

import { DeepDreamEndpoints } from "../../../../endpoints.autogen";
import { APIDeepDreamInterface } from "../stores/APIInterfaces";
import { observer } from "mobx-react";
import { opTechnique, techniqueTarget, opTechniqueRenderProps } from "./Technique";


const Main = observer(({ model, op, params }: opTechniqueRenderProps) => {
    const num_steps = 1024;
    const [imageUrl, $imageUrl] = useState<string | undefined>();
    useEffect(() => {
        $imageUrl(undefined);
        (new DeepDreamEndpoints()).op_deepdream(model.id, op.id, num_steps)
            .then(function (result: APIDeepDreamInterface) {
                if (result) {
                    $imageUrl(result.images[0].image.url);
                }
            });
    }, [model.id, op.id, params]);
    return <div><img src={imageUrl} /></div>
});

const DeepDream: opTechnique = {
    id: "deep_dream",
    backendId: "lucid.deep_dream",
    title: "DeepDream",
    rank: 6,
    Description: () => <>
        An artificial, optimized image that maximizes the activations of all units in an op.
    </>,
    op: true,
    params: [

    ],
    display: {
        model: true,
        op: true,
    },
    Thumbnail: Main,
    Main,
}
export default DeepDream;


// DeepDream.params = [
//     { id: 'num_steps', label: 'Optimization Steps', inputType: 'text', dataType: 'int', default: 512, live: true },
// ];
