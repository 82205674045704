import React, {
} from 'react';


export default function Circuits({ modelId }) {

    return (
        <p>Circuits for {modelId} placeholder</p>
    );
}
