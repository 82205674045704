import { observable, computed, action } from "mobx"

import { Options } from "./Store";

export default class OpViewStore {
    //thumbnailSize
    @observable
    thumbnailSizeOptions = new Options([...Array(20).keys()].map(i => i * 10 + 50), 10);

    @computed
    get thumbnailSize() {
        return this.thumbnailSizeOptions.value;
    }

    //imageResizeBehavior
    imageResizeBehaviorOptions = new Options([
        { value: "crop", label: "Crop image" },
        { value: "scale", label: "Scale image" },
    ], 0);

    @computed
    get imageResizeBehavior() {
        return this.imageResizeBehaviorOptions.value;
    }

}
