import { observable, computed, action } from "mobx"

import { Options, Option } from "./Store";

import { techniques } from "../techniques/techniques"
import { opTechnique } from "../techniques/Technique"

export default class ModelTechniqueStore {
    @observable
    techniques = techniques.model

    @computed({ keepAlive: true })
    get techniqueOptions() {
        return new Options(this.techniques.map((t, i): Option => {
            return { id: t.id, value: t, label: t.title };
        }));
    }

    @computed({ keepAlive: true })
    get technique(): opTechnique {
        return this.techniqueOptions.value;
    }


    //thumbnailSize
    // @observable
    // thumbnailSizeOptions = new Options([
    //     { value: 70, label: "75" },
    //     { value: 100, label: "100" },
    //     { value: 150, label: "150" },
    //     { value: 200, label: "200" },
    //     { value: 250, label: "250" },
    // ], 2);

    // @computed
    // get thumbnailSize() {
    //     return this.thumbnailSizeOptions.value;
    // }

    // //imageResizeBehavior
    // imageResizeBehaviorOptions = new Options([
    //     { value: "crop", label: "Crop image" },
    //     { value: "scale", label: "Scale image" },
    // ], 1);

    // @computed
    // get imageResizeBehavior() {
    //     return this.imageResizeBehaviorOptions.value;
    // }

}
