export const mergeOps = function (ops, opsData, weightsData) {
    const mergedOps = ops.map(op => {
        const d = opsData[op.name];
        let convSize = false;
        if (d['conv_weight']) {
            const opWeightsData = weightsData[d['conv_weight']];
            convSize = opWeightsData.shape.slice(0, 2);
        }
        return {
            id: op.id,
            title: op.cleanName,
            name: op.name,
            techniques: op.techniques,
            cleanName: op.cleanName,
            inputs: d['inputs'],
            type: d['op_type'] + '',
            channels: +d['channels'],
            convSize: convSize,
            receptiveFieldSize: d['receptive_field_size'],
            defaultSize: d['default_size'],
        }
    })
    return mergedOps;
}

export const removeInceptionV1Heads = function (layout) {
    layout = JSON.parse(JSON.stringify(layout));
    let rootSequence = layout["Sequence"];
    const rootTail1 = rootSequence[14]["Branch"][1]["Sequence"];
    rootSequence.pop();
    layout["Sequence"] = rootSequence.concat(rootTail1);
    rootSequence = layout["Sequence"]
    const rootTail2 = rootSequence[20]["Branch"][1]["Sequence"];
    rootSequence.pop();
    layout["Sequence"] = rootSequence.concat(rootTail2);
    return layout;
}

export const cleanOps = function (ops) {
    const names = ops.map(op => {
        if (op.cleanName) {
            return op.cleanName;
        } else {
            return op.name;
        }
    });

    const meaningfulOps = names.filter(n => {
        return !(
            n.startsWith("nput", 1) ||
            n.startsWith("ogits", 1) ||
            n.startsWith("utput", 1) ||
            n.startsWith("rediction", 1) ||
            n.startsWith("laceholder", 1) ||
            n === "add:0"
        )
    });
    meaningfulOps.sort();
    const l = meaningfulOps.length;
    let i = 0;
    const m1 = meaningfulOps[0], m2 = meaningfulOps[meaningfulOps.length - 1];
    while (i < l && m1.charAt(i) === m2.charAt(i)) {
        i++;
    }

    const removePrefix = m1.substring(0, i);

    //TODO: Hack


    ops.forEach(op => {
        let n = op.cleanName ? op.cleanName : op.name;
        n = n.replace(":0", "");
        n = n.replace(removePrefix, "");
        //TODO: Hack
        // inceptionv3_slim
        // inceptionv4_slim
        n = n.replace("InceptionV4/", "")
        n = n.replace("InceptionV3/", "")
        // inceptionv1_caffe_places365
        n = n.replace("inception_", "")
        n = n.replace("inception_", "")
        // resnetv2_50_slim
        // resnetv2_152_slim
        n = n.replace("bottleneck_v2/", "")
        op.cleanName = n;
    });
    if (removePrefix.length > 0) {
        cleanOps(ops);
    }
    return ops;
}