import React, {
} from 'react';
import { observer } from "mobx-react";
import OpEdge from "./OpEdge";
import styles from "../diagrams/OpDiagram.module.css";
import { useStores } from "../stores/context";
import { Model } from "../stores/ModelStore";

import localStyles from "./ModelDiagram.module.css";
import { Link } from "react-router-dom";
import { TechniqueParamsWrapper } from "../../../../helpers/SummaryHelpers";
import { format } from "d3-format";
const f = format(",");

type ModelDiagramProps = {
    opSize: number,
    matchUrl: string,
    setDiagramHeight: Function,
    descriptionWidth: number,
}

const ModelDiagram = observer(({ opSize, matchUrl, setDiagramHeight, descriptionWidth = 50 }: ModelDiagramProps) => {
    const { modelViewStore, microscopeStore } = useStores();
    const { model } = microscopeStore;

    const thumbnailWidth = opSize;
    const thumbnailHeight = opSize;
    const descriptionWidthRoot = 300;
    const opWidth = opSize + descriptionWidth;
    const opHeight = opSize;
    const opMarginX = 0;
    const opMarginY = 10;
    const groupLayoutMargin = 0.5;
    const groupMargin = 20;
    const stageMarginLeft = thumbnailWidth / 2 + groupMargin + 30;
    const stageMarginTop = 30; //opHeight / 2 + opMarginY;

    const graph = model?.layout;
    // const tree = graph?.tree;
    // const edges = graph?.edges;
    // const nodes = graph?.nodes;
    // const groups = graph?.groups;
    const stageWidth = graph?.tree?.layoutWidth ? graph?.tree?.layoutWidth * (opWidth + opMarginX) + 2 * opMarginX + 2 * stageMarginLeft : 0;
    const stageHeight = graph?.tree?.layoutHeight ? graph?.tree?.layoutHeight * (opHeight + opMarginY) + 2 * opMarginY + 2 * stageMarginTop : 0;
    console.log("!!graph", graph)
    setDiagramHeight(stageHeight);

    return (
        <div style={{ flexGrow: 0, display: "flex", justifyContent: "center" }}>
            {graph ? (
                <div className={styles.root}>
                    <svg className={styles.opLayout} width={stageWidth} height={stageHeight} >
                        <g transform={`translate(${stageMarginLeft}, ${stageMarginTop})`}>

                            {/* Groups */}
                            <g className="groups">
                                {graph.groups.map(group => (
                                    <g
                                        key={group.id}
                                        transform={`
                                        translate(${((group.x) * (opWidth + opMarginX) - thumbnailWidth / 2 - groupMargin)}, ${group.y * (opHeight + opMarginY) - groupMargin})
                                    `}
                                        className={styles.group}
                                    >
                                        <rect
                                            rx={20}
                                            width={group.layoutWidth * (opWidth + opMarginX) - opMarginX + groupMargin * 2}
                                            height={(group.layoutHeight - groupLayoutMargin) * (opHeight + opMarginY) - opMarginY + groupMargin * 2}
                                        />
                                    </g>
                                ))}
                            </g>
                            {/* Edges */}
                            <g className="edges">
                                {graph.edges.map(edge => (
                                    <g key={edge.id} className={styles.edge}>
                                        <OpEdge
                                            style={{ strokeWidth: 1.5, strokeOpacity: 0.8 }}
                                            sx={edge.start.x * (opWidth + opMarginX)}
                                            sy={edge.start.y * (opHeight + opMarginY)}
                                            ex={edge.end.x * (opWidth + opMarginX)}
                                            ey={edge.end.y * (opHeight + opMarginY)}
                                        />
                                    </g>
                                ))}
                            </g>
                        </g>
                    </svg>
                    <div className={localStyles.html}>
                        {/* Labels */}
                        {graph.nodes.map((node, i) => {
                            const nodeMetadata = [
                                { name: "Type", value: node.op.type },
                                { name: "Channels", value: node.op.channels },
                            ];
                            if (node.op.convSize) {
                                nodeMetadata.push({ name: "Conv size", value: node.op.convSize })
                            }
                            const linkUrl = `${matchUrl}/${node.id}`;
                            const localDescriptionWidth = node.depth === 0 ? descriptionWidthRoot : descriptionWidth;
                            const width = opSize + localDescriptionWidth;
                            return (
                                <Link
                                    key={node.id}
                                    to={linkUrl}
                                >
                                    <div
                                        className={localStyles.node}
                                        style={{
                                            position: "absolute",
                                            left: node.x * (opWidth + opMarginX) - thumbnailWidth / 2 + stageMarginLeft + "px",
                                            top: node.y * (opHeight + opMarginY) + stageMarginTop + "px",
                                            right: 0,
                                            width: width,
                                            height: opHeight,
                                            zIndex: graph.nodes.length - i,
                                        }}
                                    >
                                        {node.op.techniques["lucid.feature_vis"] ? (
                                            <div className={localStyles.thumbnail} style={{ flexBasis: thumbnailWidth, height: thumbnailHeight }}>
                                            </div>

                                        ) : (
                                                <div className={localStyles.thumbnailNoData} style={{ flexBasis: thumbnailWidth, height: thumbnailHeight }}>
                                                </div>
                                            )}
                                        <div className={localStyles.description} style={{ width: localDescriptionWidth }}>
                                            <div className={localStyles.name}>{node.op.cleanName}</div>
                                            <div className={localStyles.label}>{node.op.type}</div>
                                            <div className={localStyles.label}>{f(node.op.channels)}</div>
                                            <div className={localStyles.label}>{node.op.convSize ? JSON.stringify(node.op.convSize) : ""}</div>
                                        </div>

                                    </div>
                                </Link>
                            )
                        })}
                        <Nodes
                            nodes={graph.nodes}
                            opWidth={opWidth}
                            opHeight={opHeight}
                            opMarginX={opMarginX}
                            opMarginY={opMarginY}
                            thumbnailWidth={thumbnailWidth}
                            thumbnailHeight={thumbnailHeight}
                            stageMarginLeft={stageMarginLeft}
                            stageMarginTop={stageMarginTop}
                        />

                    </div>

                </div>

            ) : null}
        </div>
    );
})
export default ModelDiagram;

type NodesProps = {
    nodes: any[],
    opWidth: number,
    opHeight: number,
    opMarginX: number,
    opMarginY: number,
    thumbnailWidth: number,
    thumbnailHeight: number,
    stageMarginLeft: number,
    stageMarginTop: number,
}
const Nodes = observer(({ nodes, opWidth, opHeight, opMarginX, opMarginY, thumbnailWidth, thumbnailHeight, stageMarginLeft, stageMarginTop }: NodesProps) => {
    const { microscopeStore, modelViewStore, modelTechniqueStore } = useStores();
    const viewportTop = modelViewStore.scrollTop;
    const viewportHeight = modelViewStore.scrollFrameHeight;

    const { technique } = modelTechniqueStore;
    const { model } = microscopeStore;

    return (
        <div style={{ pointerEvents: "none" }}>
            {model && nodes.map((node, i) => {
                const top = node.y * (opHeight + opMarginY) + stageMarginTop;
                const left = node.x * (opWidth + opMarginX) - thumbnailWidth / 2 + stageMarginLeft;
                // viewportTop, viewportHeight
                const overscan = 500;
                const visible = node.op.techniques["lucid.feature_vis"] && top > viewportTop - overscan && top < viewportTop + viewportHeight + overscan
                return (
                    <div
                        key={node.id}
                        className={localStyles.node}
                        style={{
                            position: "absolute",
                            left,
                            top,
                            right: 0,
                            zIndex: nodes.length - i,
                        }}
                    >
                        {visible ? (
                            <div className={localStyles.thumbnail} style={{ flexBasis: thumbnailWidth, height: thumbnailHeight }}>
                                <technique.Thumbnail model={model} op={node.op} />
                            </div>

                        ) : (
                                null
                            )}


                    </div>
                )
            })}

        </div>
    );
});