export const caricatureImages = [
    {
        "title": "square",
        "tag": "synthetic"
    },
    {
        "title": "four-squares",
        "tag": "synthetic"
    },
    {
        "title": "circle",
        "tag": "synthetic"
    },
    {
        "title": "four-circles",
        "tag": "synthetic"
    },
    {
        "title": "star",
        "tag": "synthetic"
    },
    {
        "title": "four-shapes",
        "tag": "synthetic"
    },
    {
        "title": "patterns",
        "tag": "synthetic"
    },
    {
        "title": "horizontal-ticks",
        "tag": "synthetic"
    },
    {
        "title": "vertical-ticks",
        "tag": "synthetic"
    },
    {
        "title": "dog-drawing",
        "tag": "synthetic"
    },
    {
        "title": "textures",
        "tag": "synthetic"
    },
    {
        "author": "Sam 🐷",
        "slug": "sam-5qu17cF-Jv0-unsplash",
        "title": "ocean",
        "tag": "place"
    },
    {
        "author": "Izzy Boscawen",
        "slug": "izzy-boscawen-HrcpO5MCedQ-unsplash",
        "title": "pasta",
        "tag": "thing"
    },
    {
        "author": "Veri Ivanova",
        "slug": "veri-ivanova-p3Pj7jOYvnM-unsplash",
        "title": "watch",
        "tag": "thing"
    },
    {
        "author": "Leonardo Wong",
        "slug": "leonardo-wong-7pGehyH7o64-unsplash",
        "title": "vase",
        "tag": "thing"
    },
    {
        "author": "Wade Lambert",
        "slug": "wade-lambert-_PyDYwQwu9U-unsplash",
        "title": "car",
        "tag": "thing"
    },
    {
        "author": "John Matychuk",
        "slug": "john-matychuk-dJdcb11aboQ-unsplash",
        "title": "sign",
        "tag": "thing"
    },
    {
        "author": "Alesia Kazantceva",
        "slug": "alesia-kazantceva-Ob1grL3RBvI-unsplash",
        "title": "biking",
        "tag": "action"
    },
    {
        "author": "Karen Zhao",
        "slug": "karen-zhao-lMCK7WkGIGE-unsplash",
        "title": "reading",
        "tag": "action"
    },
    {
        "author": "Hugh Han",
        "slug": "hugh-han-5pkYWUDDthQ-unsplash",
        "title": "commuting",
        "tag": "action"
    },
    {
        "author": "Pascal van de Vendel",
        "slug": "pascal-van-de-vendel-gcG_b9ijyqU-unsplash",
        "title": "diving",
        "tag": "action"
    },
    {
        "author": "Kaitlyn Baker",
        "slug": "kaitlyn-baker-vZJdYl5JVXY-unsplash",
        "title": "typing",
        "tag": "action"
    },
    {
        "author": "Jamie Street",
        "slug": "jamie-street-wcO2PWLuQ3U-unsplash",
        "title": "dog",
        "tag": "animal"
    },
    {
        "author": "Jeroen Bosch",
        "slug": "jeroen-bosch-52l3x9yqn0k-unsplash",
        "title": "spider",
        "tag": "animal"
    },
    {
        "author": "Gary Bendig",
        "slug": "gary-bendig-WPmPsdX2ySw-unsplash",
        "title": "geese",
        "tag": "animal"
    },
    {
        "author": "Bruno van der Kraan",
        "slug": "bruno-van-der-kraan-OgnamdgLcHQ-unsplash",
        "title": "cat",
        "tag": "animal"
    },
    {
        "author": "David Clode",
        "slug": "david-clode-2slBHG3HtdA-unsplash",
        "title": "frog",
        "tag": "animal"
    },
    {
        "author": "Cristofer Jeschke",
        "slug": "cristofer-jeschke-5y2LBskO-nA-unsplash",
        "title": "city",
        "tag": "place"
    },
    {
        "author": "Johann Siemens",
        "slug": "johann-siemens-EPy0gBJzzZU-unsplash",
        "title": "field",
        "tag": "place"
    },
    {
        "author": "Kinshuk Bose",
        "slug": "kinshuk-bose-oXHCpSjWSqs-unsplash",
        "title": "cliff",
        "tag": "place"
    },
    {
        "author": "Roberto Nickson",
        "slug": "roberto-nickson-bwIqQ5qQhXs-unsplash",
        "title": "room",
        "tag": "place"
    },
    {
        "author": "Paul Hanaoka",
        "slug": "paul-hanaoka-o6RbK3y7mK4-unsplash",
        "title": "cat-face",
        "tag": "animal"
    },
    {
        "title": "flowers",
        "tag": "deprecated"
    },
    {
        "title": "dog-cat",
        "tag": "deprecated"
    },
    {
        "title": "clock",
        "tag": "deprecated"
    },
    {
        "title": "train",
        "tag": "deprecated"
    },
    {
        "title": "books",
        "tag": "deprecated"
    }
]