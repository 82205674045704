import React, {
    useState,
} from 'react';

import { Op } from "../stores/OpStore";
import { Model } from "../stores/ModelStore";

import styles from "./OpDiagram.module.css";

import OpIcon from "./OpIcon";
import OpEdge from "./OpEdge";
import OpHitAreas from "./OpHitAreas";
import OpHoverCard from "./OpHoverCard";
import Tooltip from "../../../../components/Tooltip";

import { format } from "d3-format";
import { observer } from 'mobx-react';

const f = format(",");

type OpDiagramProps = {
    path: string,
    model: Model,
    opId: string | null,
    opened: boolean,
}
const OpDiagram = observer(({ path, model, opId, opened }: OpDiagramProps) => {
    const stageWidth = 300;
    const opWidth = 22;
    const opHeight = 16;
    const opMarginX = 6;
    const opMarginY = 6;
    const groupLayoutMargin = 0.5;
    const stageMarginLeft = opWidth + 30;
    const stageMarginTop = opHeight / 2 + opMarginY;
    const groupMargin = groupLayoutMargin * (opHeight + opMarginY) / 2 + opMarginY / 2;

    const graph = model.layout;

    const [highlightedOp, $highlightedOp] = useState<string>("");
    const [tooltip, $tooltip] = useState();
    const [highlightEl, $highlightEl] = useState<SVGGElement | undefined | null>();

    const visibleOps: any[] = [];
    // const [opScrollTarget, $opScrollTarget] = useState([]);

    const selectedOp = opId;

    const techniqueId = 'lucid.feature_vis';  // FIXME: get rid of this somehow
    function opInteractionClasses(op: Op) {
        let matches = [];
        if (selectedOp === op.id) { matches.push(styles.selected) }
        if (highlightedOp === op.id || visibleOps.includes(op.id)) { matches.push(styles.highlighted) }
        if (op.techniques[techniqueId]) { matches.push(styles.available) }
        return matches.join(" ");
    }

    function findNode(opId: string | null) {
        const matches = graph.nodes.find((node: any) => node.op.id === opId);
        return matches;
    }

    const highlightedNode = findNode(highlightedOp);
    const selectedNode = findNode(selectedOp);

    return (
        <>
            {graph && graph.tree ? (


                <div
                    className={styles.root + " " + (opened ? styles.light : styles.dark)}
                    onMouseLeave={() => { $highlightedOp("") }}
                    style={{ height: graph.tree.layoutHeight ? graph.tree.layoutHeight * (opHeight + opMarginY) + 2 * opMarginY : 0 }}
                >

                    <svg className={styles.opLayout} width={stageWidth} height={graph.tree.layoutHeight ? graph.tree.layoutHeight * (opHeight + opMarginY) + 2 * opMarginY : 0} >
                        <g transform={`translate(${stageMarginLeft}, ${stageMarginTop})`}>

                            {/* Groups */}
                            <g className="groups">
                                {graph.groups.map((group) => (
                                    <g
                                        key={group.id}
                                        transform={`
                                translate(${((group.x) * (opWidth + opMarginX) - opWidth / 2 - groupMargin)}, ${group.y * (opHeight + opMarginY) - opHeight / 2 - groupMargin})
                            `}
                                        className={styles.group}
                                    >
                                        <rect
                                            rx={opWidth / 4 + groupMargin}
                                            width={group.layoutWidth * (opWidth + opMarginX) - opMarginX + groupMargin * 2}
                                            height={(group.layoutHeight - groupLayoutMargin) * (opHeight + opMarginY) - opMarginY + groupMargin * 2}
                                        />
                                    </g>
                                ))}
                            </g>

                            {/* Edges */}
                            <g className="edges">
                                {graph.edges.map((edge) => (
                                    <g key={edge.id} className={styles.edge}>
                                        <OpEdge
                                            sx={edge.start.x * (opWidth + opMarginX)}
                                            sy={edge.start.y * (opHeight + opMarginY)}
                                            ex={edge.end.x * (opWidth + opMarginX)}
                                            ey={edge.end.y * (opHeight + opMarginY)}
                                        />
                                    </g>
                                ))}
                            </g>

                            {/* Nodes */}
                            <g className="nodes">
                                {graph.nodes.map((node) => (
                                    <g
                                        key={node.id}
                                        transform={`translate(${((node.x) * (opWidth + opMarginX))}, ${node.y * (opHeight + opMarginY)})`}
                                        className={styles.op + ' ' + opInteractionClasses(node.op)}
                                        ref={(el) => {
                                            if (node.op.id === highlightedOp) {
                                                $highlightEl(el)
                                            }
                                        }}
                                    >
                                        <OpIcon type={node.op.type} op={node.op} width={opWidth} height={opHeight} />
                                    </g>
                                ))}
                            </g>

                            {/* Small labels */}
                            <g className="smallLabels">
                                <g className={styles.smallLabels}>
                                    {graph.nodes.map(node => {
                                        if (node.depth === 0) {
                                            return (
                                                <g key={node.id} transform={`translate(${((node.x) * (opWidth + opMarginX) - opWidth / 2)}, ${node.y * (opHeight + opMarginY)})`}>
                                                    <text dx="-8">{isNaN(node.op.channels) ? "" : f(node.op.channels)}</text>
                                                </g>
                                            )
                                        } else {
                                            return null;
                                        }
                                    })}
                                </g>
                            </g>

                            <OpHitAreas
                                path={path}
                                nodes={graph.nodes}
                                nodeWidth={opWidth + opMarginX}
                                nodeHeight={opHeight + opMarginY}
                                extent={[[-stageMarginLeft, -stageMarginTop], [stageWidth - stageMarginLeft, graph.tree.layoutHeight * (opHeight + opMarginY)]]}
                                onHighlight={$highlightedOp}
                                onTooltip={$tooltip}
                            // onClick={onClickOp}
                            />
                        </g>
                    </svg>
                    <div className={styles.htmlLabels}>
                        {/* Labels */}
                        {graph.nodes.filter(n => n.depth === 0 || (n.id === selectedOp && !(highlightedNode && selectedNode && highlightedNode.x > selectedNode.x && highlightedNode.y === selectedNode.y))).map(node => (
                            <div
                                className={styles.label + " " + opInteractionClasses(node.op)}
                                key={node.id}
                                style={{
                                    position: "absolute",
                                    left: (node.x) * (opWidth + opMarginX) + (opWidth / 2 + opMarginX) + stageMarginLeft + "px",
                                    top: node.y * (opHeight + opMarginY) + stageMarginTop + "px",
                                    right: 0,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {node.op.cleanName}
                            </div>
                        ))}

                    </div>
                    {
                        highlightedNode && highlightEl && tooltip ?
                            <Tooltip target={highlightEl}>
                                <div className={styles.hoverContainer} >
                                    <OpHoverCard op={highlightedNode.op} />
                                </div>
                            </Tooltip>
                            : null
                    }
                </div>
            ) : null}
        </>
    );
});
export default OpDiagram;