// React
import React from 'react';
// Icons
import Expand from '../icons/Expand';
import UnExpand from '../icons/UnExpand';
import Minimize from '../icons/Minimize';
import UnMinimize from '../icons/UnMinimize';
import Close from '../icons/Close';
// Routing
import { Link } from "react-router-dom";
// Styles
import styles from './Button.module.css';

enum IconButtonIconType {
    Minimize = "minimize",
    UnMinimize = "unminimize",
    Expand = "expand",
    UnExpand = "unexpand",
    Close = "close",
}

type IconButtonProps = {
    icon: string,
    onClick?: any,
    url?: string | undefined,
    theme?: "dark" | undefined,
}
export default function IconButton({ icon, theme, onClick, url }: IconButtonProps) {
    function renderIcon(id: string) {
        if (id === IconButtonIconType.Minimize) {
            return <Minimize />
        }
        if (id === IconButtonIconType.UnMinimize) {
            return <UnMinimize />
        }
        if (id === IconButtonIconType.Expand) {
            return <Expand />
        }
        if (id === IconButtonIconType.UnExpand) {
            return <UnExpand />
        }
        if (id === IconButtonIconType.Close) {
            return <Close />
        }
    }

    // If we have a url, let's use a link component
    if (url) {
        return (
            <Link
                className={[styles.iconButton, (theme === "dark" ? styles.darkTheme : null)].join(" ")}
                to={url}
            >
                {renderIcon(icon)}
            </Link>
        );

        // If we don't have a url, let's use a button
    } else {
        return (
            <button
                className={[styles.iconButton, (theme === "dark" ? styles.darkTheme : null)].join(" ")}
                onClick={onClick}
            >
                {renderIcon(icon)}
            </button>
        );
    }

}