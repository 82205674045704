import { observable, computed, action } from "mobx"

import { Options } from "./Store";
import { techniques } from "../techniques/techniques"
import { unitTechnique } from "../techniques/Technique"

export default class UnitTechniqueStore {
    @observable
    techniques = techniques.unit

}
