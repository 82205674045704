import React from "react";
import { observable, computed, action } from "mobx";

import MicroscopeStore from "./MicroscopeStore";
import ModelViewStore from "./ModelViewStore";
import OpViewStore from "./OpViewStore";
import UnitTechniqueStore from "./UnitTechniqueStore";

const microscopeStore = new MicroscopeStore();

export const storesContext = React.createContext({
    microscopeStore: microscopeStore,

    modelViewStore: new ModelViewStore(),
    modelTechniqueStore: microscopeStore.panels.model.techniqueStore,

    opViewStore: new OpViewStore(),
    opTechniqueStore: microscopeStore.panels.op.techniqueStore,

    unitTechniqueStore: new UnitTechniqueStore(),
});

export const useStores = () => React.useContext(storesContext);

