import { observable, computed, action, flow, autorun } from "mobx"
import { EncyclopediaGlobalState } from "../../../../endpoints.autogen";
import { cleanOps, mergeOps, removeInceptionV1Heads } from "../diagrams/munge";
import { extract } from "../diagrams/layout";
import { LoadingStatus } from "./Store";
import { APIModelInterface } from "./APIInterfaces";
import { Op } from "./OpStore";
import { format } from "d3-format";


const f = format(",");

type GraphMetadata = {
    edges: any[];
    groups: any[];
    nodes: any[];
    tree: any;
}

const emptyGraph: GraphMetadata = {
    edges: [],
    groups: [],
    nodes: [],
    tree: {}
}

export type Layout = {
    nodes: any[],
    edges: any[],
    groups: any[],
    tree: {
        outputs?: any,
        layoutWidth: number,
        layoutHeight: number
    },
}


export class Model {
    constructor(id: string, props?: any) {
        // if we're initializing we can set the id
        // hydrate does not allow this
        this.id = id;
        if (props) {
            this.hydrate((props as Model))
        }
        this.loadModelGraph();
    }

    @observable
    id: string;

    @observable
    title: string = "Loading";

    @observable
    description: string = "…";

    @observable
    citation: string = "";

    @observable
    url: string = "";

    @observable
    date: string = "";

    @observable
    order: number = 0;

    @observable
    graphLoadingStatus: LoadingStatus = LoadingStatus.none;

    @observable
    ops: Op[] = []

    @observable layoutResponse: any | null = null;


    // kepAlive caches the computed value.
    // https://mobx.js.org/refguide/computed-decorator.html#computed-keepalive
    @computed({ keepAlive: true })
    get layout(): Layout {
        const extractOutput = extract(this.layoutResponse, this.ops, 0.5, "hybrid");
        const tree: any = extractOutput.tree;
        const nodes: any = extractOutput.nodes;
        const edges: any = extractOutput.edges;
        const groups: any = extractOutput.groups;
        return { nodes, edges, groups, tree };
    }

    getOpById(id: string): Op | undefined {
        return this.ops.find(op => op.id === id)
    }

    @action
    hydrate(m: Model) {
        try {
            const props = ["title", "description", "citaiton", "url", "date", "order"]
            for (const key of props) {
                // we don't allow overwriting the id except in the constructor
                if (m.hasOwnProperty(key)) {
                    const value = (m as any)[key];
                    if (Array.isArray(value)) {
                        (this as any)[key].replace(value);
                    } else {
                        (this as any)[key] = value;
                    }
                }
            }
        } catch (e) {
            throw new Error(e);
        }
    }

    loadModelGraph = flow(function* () {
        console.log("!! Loading graph for ", this.id)
        this.graphLoadingStatus = LoadingStatus.pending;
        try {
            const modelResponse = (yield (new EncyclopediaGlobalState().model(this.id))) as APIModelInterface;
            const loadedOps = cleanOps(modelResponse.ops);
            const mergedOps = mergeOps(loadedOps, modelResponse.graph_metadata.ops, modelResponse.graph_metadata.weights);
            mergedOps.forEach((op: Op) => {
                if (op.channels) {
                    const units = [];
                    for (let c = 0; c < op.channels; c++) {
                        units.push({
                            id: c,
                            title: `Unit ${f(c)}`
                        })
                    }
                    op.units = units;
                }
            });
            this.ops.replace(mergedOps);
            let layout = modelResponse.graph_metadata.layout;
            //TODO: this is hacky
            if (this.id === "inceptionv1") {
                layout = removeInceptionV1Heads(layout);
            }
            console.log("!!layout", layout)
            this.layoutResponse = layout;
            this.graphLoadingStatus = LoadingStatus.success;
        } catch (e) {
            this.graphLoadingStatus = LoadingStatus.error;
            throw new Error(`Error in loading or processing graph_metadata for ${this.id}. ${e}`)
        }
    })

}