import React, { } from 'react';

type OpEdgeProps = {
    sx: number,
    sy: number,
    ex: number,
    ey: number,
    style?: object
}
export default function OpEdge({ sx, sy, ex, ey, style }: OpEdgeProps) {

    const my = sy + (ey - sy) / 2;// - 30;
    const offset = (sy - ey) / 4//20

    return (
        <g>
            <path
                style={style}
                fill="none"
                d={`
                    M${sx} ${sy}
                    C${sx} ${sy - offset} ${ex} ${my + offset} ${ex} ${my}
                    L${ex} ${ey}
            `} />
        </g>
    );
}