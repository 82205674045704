import {
    useState,
    useEffect,
} from "react";

export default function useKeyPress(targetKey) {
    const [keyPressed, $keyPressed] = useState(false);



    useEffect(() => {
        function onDown(event) {
            const key = event.key;
            if (key === targetKey) {
                $keyPressed(true);
            }
        }

        function onUp(event) {
            const key = event.key;
            if (key === targetKey) {
                $keyPressed(false);
            }
        }
        window.addEventListener("keydown", onDown);
        window.addEventListener("keyup", onUp);

        return () => {
            window.removeEventListener("keydown", onDown);
            window.removeEventListener("keyup", onUp);
        }
    }, [targetKey])

    return keyPressed
}