import React, { useEffect } from 'react';

import styles from "./About.module.css"

export default function About() {
    useEffect(() => {
        document.title = `OpenAI Microscope / About`;
    }, []);
    return (
        <div className={styles.root}>
            <div className={styles.page}>


                <h1>About OpenAI Microscope</h1>

                <p>OpenAI Microscope is a collection of visualizations of every significant layer and neuron of several common “model organisms” which are often studied in interpretability. Microscope makes it easier to analyze the features that form inside these neural networks, and we hope it will help the research community as we move towards understanding these complicated systems.</p>

                <h3>Who is this for?</h3>

                <p>While we’re making this available to anyone who’s interested in exploring how neural networks work, we think the primary value is in providing persistent, shared artifacts to facilitate long-term comparative study of these models. We also hope that researchers with adjacent expertise — neuroscience, for instance — will find value in being able to more easily approach the internal workings of these vision models.</p>


                <h3>How do I use it? Can you point me at some interesting things?</h3>

                <p>The OpenAI Microscope is based on two concepts, a location in a model and a technique. Metaphorically, the location is where you point the microscope, the technique is what lens you affix to it.</p>

                <p>Our models are composed of a graph of “nodes” (the neural network layers), which are connected to each other through “edges.” Each op contains hundreds of “units”, which are roughly analogous to neurons. Most of the techniques we use are useful only at a specific resolution. For instance, feature visualization can only be pointed at a “unit”, not its parent “node”. </p>

                <p>The <a href="https://distill.pub/2020/circuits/zoom-in/">circuits collaboration</a> is a great place to find interesting units to look at, from low-level features like curve detectors
                (eg. <a href="/models/inceptionv1/mixed3b_0/379">3b:379</a>,
                <a href="/models/inceptionv1/mixed3b_0/406">406</a>,
                <a href="/models/inceptionv1/mixed3b_0/385">385</a>,
                <a href="/models/inceptionv1/mixed3b_0/343">343</a>,
                <a href="/models/inceptionv1/mixed3b_0/342">342</a>,
                <a href="/models/inceptionv1/mixed3b_0/388">388</a>, ...)
            to high level features like pose-invariant dog detectors (eg.
                <a href="/models/inceptionv1/mixed4b_0/409">4b:409</a>,
                <a href="/models/inceptionv1/mixed4b_0/418">418</a>)
            or car detectors
                (<a href="/models/inceptionv1/mixed4c_0/447">4c:447</a>).</p>

                <h3>What visualizations are available in Microscope?</h3>

                <p>We’re currently including a couple visualizations we find particularly helpful in the microscope, including <a href="https://distill.pub/2017/feature-visualization/">feature visualizations</a>, <a href="https://ai.googleblog.com/2015/06/inceptionism-going-deeper-into-neural.html">DeepDream</a>, dataset examples (images that cause neurons to fire strongly), and synthetic tuning curves (neuroscience-inspired plots of how units respond to synthetic image families). We expect to add more over time. Each technique is explained when it is shown.</p>

                <h3>What if I find something interesting?</h3>

                <p>Please share it on twitter! You can also join the Distill slack (<a href="http://slack.distill.pub">join link</a>) <code>#circuits</code> channel for more detailed discussion of features and circuits.</p>

                <h3>Can I reuse these images? What‘s the license?</h3>

                <p>
                    In general, we're releasing visualizations generated by Microscope under the <a href="https://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution license 4.0 (CC-BY 4.0)</a>. Basically, we'd love for you to use them and just ask that you attribute reused images to “OpenAI Microscope”.
            </p>

                <p>
                    The one exception to visualizations being CC-BY-4.0 is the dataset example visualizations, which is derived from the <a href="http://www.image-net.org/">ImageNet</a> dataset.
                We've marked this visualization in the interface.
            </p>

                <h3>Why are only some models available?</h3>

                <p>Just as biologists often focus on the study of a few “model organisms,” Microscope focuses on exploring a small number of models in detail. Our initial release includes nine frequently studied vision models, but we plan to expand this set over time.</p>

                <p>Even the models we've included result in hundreds of thousands of neurons and many model idiosyncrasies. Making everything work smoothly turns out to be more difficult than one would expect!</p>

                <h3>Can I use this on my own model?</h3>

                <p>All the underlying visualizations displayed by Microscope are available in the <a href="https://github.com/tensorflow/lucid">lucid library</a>, which we help maintain.</p>

                <p>Microscope itself is not currently available for use with custom models. Generating the millions of images and underlying data for an Microscope visualization requires running lots of distributed jobs. At present, our tooling for doing this isn't usable by anyone other than us and is entangled with other infrastructure.</p>



                <h3>Where can I report bugs or irregularities?</h3>

                <p>Please report bugs to <a href="mailto:microscope@openai.com">microscope@openai.com</a>.</p>



            </div>
        </div>
    );
}