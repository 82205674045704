import { observable, computed, action, decorate } from "mobx"

import { Options } from "./Store";

export default class ModelViewStore {

    @observable
    thumbnailSizeOptions = new Options([...Array(20).keys()].map(i => i * 10 + 50), 3);

    @computed
    get thumbnailSize() {
        return this.thumbnailSizeOptions.value;
    }

    @observable
    descriptionWidthOptions = new Options([...Array(20).keys()].map(i => i * 10 + 50));

    @computed
    get descriptionWidth() {
        return this.descriptionWidthOptions.value;
    }

    // Scrolling of the model view window
    @observable
    scrollTop = 0;

    @action
    $scrollTop = (value: number) => {
        this.scrollTop = value;
    }

    @observable
    scrollFrameHeight = 0;

    @action
    $scrollFrameHeight = (value: number) => {
        this.scrollFrameHeight = value;
    }

    @observable
    scrollContentHeight = 0;

    @action
    $scrollContentHeight = (value: number) => {
        this.scrollContentHeight = value;
    }

    @computed
    get scrollTopPercentage() {
        return this.scrollTop / this.scrollContentHeight;
    }

    @computed
    get scrollFrameHeightPercentage() {
        return this.scrollFrameHeight / this.scrollContentHeight;
    }
}