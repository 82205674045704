import React, { } from 'react';
import { observer } from "mobx-react";

import { Op } from "../stores/OpStore";

type OpIconProps = {
    type: string,
    op: Op,
    width: number,
    height: number,
    suppressLabels?: boolean
}

const OpIcon = observer(({ type, op, width, height, suppressLabels }: OpIconProps) => {
    function renderNodeIcon(type: string, name: string) {
        if (type === "ConcatV2" || type === "Concat") {
            return <path d={`
                M${-width / 2} ${0}
                L${0} ${-height / 2}
                L${width / 2} ${0}
                L${width / 2} ${height / 2}
                L${-width / 2} ${height / 2}
                Z
            `} />
        } else if (type.endsWith("ool")) {
            return <rect rx={width / 4} width={width} height={height / 2} x={-width / 2} y={-height / 4} />
        } else if (type === "Split") {
            return <circle r={width / 3} />
        } else if (type === "Softmax") {
            return <path style={{ "stroke": "rgb(200, 200, 200)", "fill": "none" }} d={`M0,-2.5 m-5,5 l5,-5 l5,5`} />
        } else if (type === "Placeholder" && name.includes("nput")) {
            return <path style={{ "stroke": "rgb(200, 200, 200)", "fill": "none" }} d={`M0,${-height / 2}, L0, ${height / 2}, M-5,5 l5,-5 l5,5`} />
        } else {
            return <rect rx={width / 5} width={width} height={height} x={-width / 2} y={-height / 2} />
        }
    }
    return (
        <g>
            {renderNodeIcon(type, op.title)}
            {op.convSize && !suppressLabels ? (
                <text>{op.convSize.join(",")}</text>
            ) : null}
        </g>
    );
});
export default OpIcon;