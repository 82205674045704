import React, { } from 'react';
import styles from "./OpDiagram.module.css";
import { Op } from "../stores/OpStore";
import { format } from "d3-format";
import { observer } from "mobx-react";

const f = format(",");

type OpHoverCardProps = {
    op: Op
}
const OpHoverCard = observer(({ op }: OpHoverCardProps) => {

    return (<div>
        {op ?
            (<div className={styles.hoverCard}>
                <h5>{op.title}</h5>
                <div className={styles.row}><span>type:</span> {op.type}</div>
                <div className={styles.row}><span>channels:</span> {f(op.channels)}</div>
                {op.convSize ? (
                    <div className={styles.row}><span>convolution:</span> {op.convSize.join(",")}</div>
                ) : ''}
            </div>)
            : ''
        }</div>
    )
});
export default OpHoverCard;