export const extract = function (layout, ops, groupLayoutMargin, align = "center") {
    const layoutData = mungeData(layout, ops);
    calculateBranchDepth(layoutData);
    calculateSizes(layoutData, groupLayoutMargin);
    calculatePositions(layoutData, align);
    const tree = layoutData;
    const nodes = extractNodesArray(layoutData);
    const edges = extractEdgesArray(nodes);
    const groups = extractGroupsArray(layoutData);

    return {
        nodes, edges, groups, tree
    }
}

// Transform the loaded data into something a little easier to use,
// with each node as an object with properties and an array of children.
function mungeData(node, ops) {

    function findOp(opId) {
        const matchingOp = ops.find(op => op.name === opId);
        return matchingOp;
    }

    let n = {
        outputs: [],
    };
    if (node) {
        if (typeof node === "object") {
            const keys = Object.keys(node);
            const key = keys[0];
            if (key) {
                const childKeys = node[key];
                // filtering null children is needed for ResNet like architectures, currently some branches are null
                // this is likely a bug but needs to be fixed server side in GraphMetadata creation
                // let children = childKeys.filter((child) => child !== null).map((child, ci) => mungeData(child, ops));
                let children = childKeys.map((child, ci) => mungeData(child, ops));
                if (key === "Sequence") {
                    n.type = "Sequence";
                    children.reverse();

                    children.forEach((child, ci) => {
                        child.previous = children[ci - 1];
                        child.next = children[ci + 1];
                    });
                } else if (key === "Branch") {
                    n.type = "Branch";
                }
                children.forEach((child, ci) => {
                    child.parent = n;
                });
                n.children = children;
            } else {
                n.type = "unknown"
            }
        } else if (typeof node === "string") {
            n.type = "Op";
            n.op = findOp(node);
        }
    } else {
        n.type = "unknown";
    }
    return n;
}

// Annotates nodes with a "depth" property how many branches deep they are.
// Used to determine if a node is the trunk or not.
function calculateBranchDepth(node) {
    node.depth = node.depth ? node.depth : 0;
    if (node.children) {
        for (const child of node.children) {
            if (node.type === "Branch") {
                child.depth = node.depth + 1;
            } else {
                child.depth = node.depth;
            }
            calculateBranchDepth(child);
        }
    }
}

// Calculates all the outputs from all the nodes
// and stores the result in an "outputs" array
// function calculateOutputs(node) {

//     function findFirstNodeWithPrevious(node) {
//         if (node.previous) {
//             return node;
//         } else if (node.parent) {
//             return findFirstNodeWithPrevious(node.parent);
//         }
//     }

//     function findTrailingOp(target, matches = []) {
//         if (target) {
//             if (target) {
//                 if (target.type === "Op") {
//                     matches.push(target);
//                 } else if (target.children) {
//                     if (target.type === "Sequence") {
//                         matches = findTrailingOp(target.children[target.children.length - 1], matches);
//                     }
//                     if (target.type === "Branch") {
//                         for (const child of target.children) {
//                             matches = findTrailingOp(child, matches)
//                         }
//                     }
//                 }
//             }
//         }
//         return matches;
//     }

//     if (node.type === "Op") {
//         const n = findFirstNodeWithPrevious(node);
//         if (n) {
//             const matches = findTrailingOp(n.previous);
//             node.outputs = node.outputs.concat(matches);
//         }
//     } else if (node.children) {
//         if (node.type === "Sequence") {
//             for (const child of node.children) {
//                 calculateOutputs(child);
//             }
//         } else if (node.type === "Branch") {
//             for (const child of node.children) {
//                 calculateOutputs(child);
//             }
//         }
//     }
// }


const trunckBranchExtraMargin = 0.1;

// Calculate the widths and heights and ids of the nodes (including branches
// and sequences). This needs to be bottom-up.
function calculateSizes(node, groupLayoutMargin) {

    function sumChildren(children, property) {
        const sum = children.reduce((sum, child) => sum + child[property], 0)
        return sum;
    }

    function maxChildren(children, property) {
        return Math.max.apply(Math, children.map(c => c[property]));
    }

    if (node.type === "Op") {
        node.layoutWidth = 1;
        node.layoutHeight = 1;
        node.id = node.op.id;
    } else if (node.type === "Sequence") {
        for (const child of node.children) {
            calculateSizes(child, groupLayoutMargin);
        }
        node.children.forEach(child => {
            // Root branches need a little extra top/bottom margin
            if (node.depth === 0) {
                child.layoutHeight = (child.type === "Branch") ? child.layoutHeight + groupLayoutMargin : child.layoutHeight;
                child.layoutHeight = (child.next && child.next.type === "Branch") ? child.layoutHeight + groupLayoutMargin : child.layoutHeight;
            }
        });
        // Sequences need to contain their children.
        node.layoutWidth = maxChildren(node.children, "layoutWidth");
        node.layoutHeight = sumChildren(node.children, "layoutHeight");
        node.id = node.children.map(c => c.id).join(",");

    } else if (node.type === "Branch") {
        for (const child of node.children) {
            calculateSizes(child, groupLayoutMargin);
        }
        node.layoutWidth = sumChildren(node.children, "layoutWidth");
        // we add a little extra margins in the branches in the trunk
        // Note: this has sister code in the `caclulatePositions()` function
        if (node.depth === 0) { node.layoutWidth = node.layoutWidth + ((node.children.length - 1) * trunckBranchExtraMargin) }
        node.layoutHeight = maxChildren(node.children, "layoutHeight");
        node.id = node.children.map(c => c.id).join(",");
    } else if (node.type === "unknown") {
        node.layoutWidth = 1;
        node.layoutHeight = 1;
    }
}


// After we have sizes, we can calculate positions.
// This needs to be top-down.
function calculatePositions(node, align = "center") {

    // If we are the root node, no parent has positioned us yet,
    // so we need to provide default values.
    node.x = node.x !== undefined ? node.x : (align === "hybrid" ? 0.5 + trunckBranchExtraMargin / 2 : 0);
    node.y = node.y !== undefined ? node.y : 0;

    if (node.type === "Op") {
        // Parents position their children, so this is a noop.
    } else if (node.type === "Sequence") {
        let cumulativeY = 0;
        node.children.forEach(child => {
            child.lx = 0;
            if (align === "center") {
                child.lx = (node.layoutWidth - child.layoutWidth) / 2
            } else if (align === "left") {
                child.lx = 0;
            } else if (align === "hybrid") {
                if (child.layoutWidth > 1) {
                    child.lx = -0.5;
                    if (node.depth === 0) { child.lx -= trunckBranchExtraMargin / 2 }
                } else {
                    child.lx = 0;
                }
                // child.lx += 0.5
            }
            child.x = child.lx + node.x;
            child.ly = cumulativeY;
            child.y = child.ly + node.y;

            cumulativeY = cumulativeY + child.layoutHeight;
            calculatePositions(child, align);
        });
    } else if (node.type === "Branch") {
        let cumulativeX = 0;
        node.children.forEach(child => {
            child.lx = cumulativeX;
            if (align === "hybrid") {
                child.lx = (child.layoutWidth > 1) ? cumulativeX + 0.5 : cumulativeX;
                // child.lx += 0.5;
            }
            child.x = child.lx + node.x;
            child.ly = 0;
            child.y = child.ly + node.y;
            cumulativeX = cumulativeX + child.layoutWidth;
            // we add a little extra margins in the branches in the trunk
            // Note: this has sister code in the `calculateSizes()` function
            if (node.depth === 0) { cumulativeX += trunckBranchExtraMargin }
            calculatePositions(child, align);
        });
    }
}

// Transform the tree structure of the layoutData into flat lists
// for easier rendering.
function extractNodesArray(node, list = []) {
    if (node.type === "Op") {
        list.push(node);
    } else if (node.children) {
        node.children.forEach(child => {
            list = extractNodesArray(child, list);
        });
    }
    list.forEach((n, i) => n.i = i);
    return list;
}

// Edges have the structure {id: "", start: node, end: node}
function extractEdgesArray(nodes) {
    const nodeLookup = {};
    nodes.forEach(node => nodeLookup[node.op.name] = node);
    const list = [];
    for (const node of nodes) {
        for (const input of node.op.inputs) {
            const inputNode = nodeLookup[input];
            if (inputNode) {
                list.push({
                    id: inputNode.id + ":" + node.id,
                    start: inputNode,
                    end: node
                });

            }
        }
    }
    return list;
}

function extractGroupsArray(node, list = []) {
    if (node.children) {
        for (const child of node.children) {
            if (child.type === "Branch") {
                list.push(child);
            }
        }
    }
    return list;
}
