import React from 'react';
import {
  Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";


import Nav from "./components/Nav";
import About from "./pages/About";
import { Error } from "./pages/Alert";
import Instruments from "./pages/Instruments";
import { ScopedState } from "./scopedState";
import { useStores } from "./pages/instruments/microscope/stores/context";

const history = createBrowserHistory();
const rootState = new ScopedState();
let _didHydrate = false;
let _lastHistoryNumStateUpdates = 0;

export default function App() {

  const { microscopeStore } = useStores();
  microscopeStore.$history(history);

  // if (!_didHydrate) {
  //   rootState.hydrateFromLocation(window.location);
  //   _didHydrate = true;

  //   history.listen(function (location, action) {
  //     console.log('history listener fired', location, action);
  //     if (action === 'POP') {
  //       if (location.state && location.state.stateSnapshot) {
  //         rootState.restoreStateToSnapshot(location.state.stateSnapshot);
  //       }
  //     } else if (action === 'PUSH') {
  //       if (location.state === undefined) {
  //         console.log('replacing and enriching last history entry with current scopedState snapshot');
  //         const stateSnapshot = rootState.stateSnapshot();
  //         const searchParams = new URLSearchParams(rootState.currentScopeQueryParams());
  //         const numStateUpdates = _lastHistoryNumStateUpdates;
  //         history.replace({
  //           pathname: location.pathname,
  //           search: searchParams.toString(),
  //           // hash: '#view=list'
  //           state: { stateSnapshot: stateSnapshot, numStateUpdates: numStateUpdates },
  //         });
  //       }
  //     }
  //   });
  // }

  rootState.onURLChange = function (newQueryParams, numStateUpdates) {
    // const searchParams = new URLSearchParams(newQueryParams);
    // searchParams.sort();
    // // console.log('got new url query params', numStateUpdates, newQueryParams, searchParams.toString());
    // const currentSearchParams = (new URL(document.location)).searchParams;
    // currentSearchParams.sort();

    // if (currentSearchParams.toString() !== searchParams.toString() && _lastHistoryNumStateUpdates !== numStateUpdates) {
    //   // const updatedUrl = window.location.pathname + "?" + searchParams.toString();
    //   const stateSnapshot = rootState.stateSnapshot();
    //   // console.log('history expected updated url', updatedUrl, 'current params:', currentSearchParams.toString(), 'new params:', searchParams.toString());
    //   // console.log('saving state snapshot into history', stateSnapshot, 'root state: ', rootState, JSON.stringify(rootState.state));
    //   history.push({
    //     pathname: window.location.pathname,
    //     search: searchParams.toString(),
    //     // hash: '#view=list'
    //     state: { stateSnapshot: stateSnapshot, numStateUpdates: numStateUpdates },
    //   });
    // } else {
    //   const stateSnapshot = rootState.stateSnapshot();
    //   // console.log('updating current history entry with snapshot', stateSnapshot);
    //   history.replace({
    //     pathname: window.location.pathname,
    //     search: searchParams.toString(),
    //     // hash: '#view=list'
    //     state: { stateSnapshot: stateSnapshot, numStateUpdates: numStateUpdates },
    //   });
    // }
    // _lastHistoryNumStateUpdates = numStateUpdates;
  };



  return (
    <Router history={history}>
      <div id="tooltip"></div>
      <div className="app" style={{ width: "100vw", height: "100vh", overflow: "hidden", display: "flex", flexDirection: "column" }}>
        <Nav>
          {/* <NavLink exact to="/">Home</NavLink> */}
          <NavLink to="/models">Models</NavLink>
          <NavLink to="/about">About</NavLink>
        </Nav>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/:instrumentId">
            <Instruments rootState={rootState} />
          </Route>
          <Route path="/">
            <Index />
          </Route>
          <Route component={Error} />
        </Switch>
      </div>
    </Router>
  );
}

function Index() {
  return (
    <Redirect to="/models" />
  );
}