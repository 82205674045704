import React, {
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react';
import {StringifyMap} from "./helpers/utils";


export class ScopedState {
    constructor(scope, stateMap, parentState) {
        this.scope = scope ? scope : [];
        this.subscopes = new Map();

        this.state = stateMap ? stateMap : new Map();
        this.parent = parentState ? parentState : null;
        this.root = parentState ? parentState.root : this;
        this._currentScopes = [];
        this.onURLChange = null;
        this.onURLChangeTimeout = null;

        this._listenerIdCounter = 0;
        this._changeListeners = new Map();
        this._numStateUpdates = 0;  // only gets incremented at the root node
    }

    subscope(name) {
        if (this.subscopes.has(name)) {
            return this.subscopes.get(name);
        }
        if (!this.state.has(name)) {
            this.state.set(name, new Map());
        }
        const subscope = new ScopedState([...this.scope, name], this.state.get(name), this);
        this.subscopes.set(name, subscope);
        return subscope;
    }

    subscopeAtPath(subscopes) {
        var iterScope = this;
        for (const scopeName of subscopes) {
            iterScope = iterScope.subscope(scopeName);
        }
        return iterScope;
    }

    stateSnapshot() {
        const snapshot = new Map();

        for (const [key, value] of this.state) {
            if (value instanceof Map) {
                snapshot.set(key, this.subscope(key).stateSnapshot());
            } else {
                snapshot.set(key, value);  // FIXME: assumes that this value is a non-mutable element
            }
        }

        if (this.root === this) {
            return {
                state: snapshot,
                currentScopes: this._currentScopes.length ? this._currentScopes.map(s => s.scope) : null
            };
        } else {
            return snapshot;
        }

    }

    restoreStateToSnapshot(snapshot) {
        console.log('restoring history snapshot to ', snapshot);
        const prevState = this.root === this ? snapshot.state : snapshot;
        for (const [key, value] of prevState) {
            if (value instanceof Map) {
                this.subscope(key).restoreStateToSnapshot(value);
            } else {
                this.state.set(key, value);
            }
        }

        for (const [key, value] of this.state) {
            if (!prevState.has(key)) {
                console.log('restore history deleting key', key);
                this.state.delete(key);
            }
        }

        if (this.root === this) {
            if (snapshot.currentScopes) {
                const subscopes = snapshot.currentScopes.map(s => this.subscopeAtPath(s));
                this.setCurrentScopes(subscopes, false);
            }
            this.fireChangeListeners(true);
            console.log('restored history snapshot to ', this.state);
        }
    }

    isAncestor(otherScope) {
        // am I the ancestor of otherScope?
        if (otherScope === null) {
            return false;  // early in lifecycle there might not be another scope
        }
        var iterScope = otherScope;
        do {
            if (iterScope === this) {
                return true;
            }
            iterScope = iterScope.parent;
        } while (iterScope !== null);
        return false;
    }

    setStateValue(key, value, defaultValue, skipChangeEvents, skipChangeListeners) {
        if (!this.state.has(key) || this.state.get(key)[0] !== value) {
            this.state.set(key, [value, defaultValue]);
            this.root._numStateUpdates += 1;
        }
        if (!skipChangeListeners) {
            this.fireChangeListeners();
        }

        if (!skipChangeEvents) {
            this.currentScopes().map(s => {
                if (s === this || this.isAncestor(s)) {
                    s.scopeTouched();
                }
            });
        }
    }

    hasStateValue(key) {
        return this.state.has(key);
    }

    stateValue(key) {
        const valueAndDefault = this.state.get(key);
        return valueAndDefault !== undefined ? valueAndDefault[0] : undefined;
    }

    addChangeListener(onChangeCallback) {
        this._listenerIdCounter += 1;
        const listener = {
            id: this._listenerIdCounter,
            fn: onChangeCallback
        };

        this._changeListeners.set(listener.id, listener);
        return listener;
    }

    removeChangeListener(listener) {
        this._changeListeners.delete(listener.id);
    }

    fireChangeListeners(recursive) {
        for (const [listenerId, listener] of this._changeListeners) {
            listener.fn();
        }
        if (recursive) {
            for (const [key, subscope] of this.subscopes) {
                subscope.fireChangeListeners(true);
            }
        }
    }

    setCurrentScopes(scopes, skipURLUpdate) {
        const equals = this.root._currentScopes.length === scopes.length && this.root._currentScopes.every((e, i) => e === scopes[i]);
        if (!equals) {
            this.root._currentScopes = scopes;
            this.scopeTouched(skipURLUpdate);
        }
    }

    currentScopes() {
        return this.root._currentScopes;
    }

    scopeTouched(skipURLUpdate) {
        if (!skipURLUpdate && this.root.onURLChange) {
            if (this.root.onURLChangeTimeout !== null) {
                clearTimeout(this.root.onURLChangeTimeout);
            }
            const that = this;
            this.root.onURLChangeTimeout = setTimeout(function () {
                that.root.onURLChangeTimeout = null;
                that.root.onURLChange(that.currentScopeQueryParams(), that.root._numStateUpdates);
            }, 10);
        }
    }

    useParam(name, defaultValue, onChangeCallback) {
        if (!this.hasStateValue(name)) {
            this.setStateValue(name, defaultValue, defaultValue);
        }
        const startingValue = this.stateValue(name);

        const scope = this;

        return useScopedStateParam(this, name, startingValue, function (oldValue, newValue) {
            if (onChangeCallback) {
                onChangeCallback(oldValue, newValue);
            }
            scope.setStateValue(name, newValue, defaultValue);
        });
    }

    queryParams() {
        const params = new Map();

        const statePrefix = this.scope.join('.');
        for (const [key, value] of this.state) {
            if (value instanceof Map) {
                // FIXME: mark substate states better within this.state
                continue;
            }
            const fullKey = statePrefix + '.' + key;
            if (value[0] !== value[1]) {  // omits values that are set to defaults
                params.set(fullKey, value[0]);
            }
        }

        return params;
    }

    currentScopeQueryParams() {
        const currentScopes = this.root.currentScopes();
        var params = new Map();

        currentScopes.map(currentScope => {
            var iterScope = currentScope;
            do {
                const scopeParams = iterScope.queryParams();
                params = new Map([...params, ...scopeParams]);
                iterScope = iterScope.parent;
            } while (iterScope !== null);
        });

        return params;
    }

    hydrateFromLocation(location) {
        console.log('hydrating state from location', location);
        const searchParams = new URLSearchParams(location.search);
        for (const [key, value] of searchParams.entries()) {

            const splitParts = key.split('.');
            const scopeNames = splitParts.slice(0, splitParts.length - 1);
            const keyName = splitParts[splitParts.length - 1];


            const subscope = this.subscopeAtPath(scopeNames);
            console.log('hydrating', key, value, scopeNames, keyName, subscope);
            subscope.setStateValue(keyName, value, undefined, true);
        }
        console.log('hydration result', this.state);
    }

    paramMap(params) {
        return new ScopedStateParamMap(this, params);
    }
}


export class ScopedStateParamMap {
    constructor(scope, paramsToManage) {
        this.scope = scope;
        this.params = paramsToManage;
        this.paramsDict = new Map(paramsToManage.map((entry) => [entry.id, entry]));

        this._scopeChangeListener = null;
        this._listenerIdCounter = 0;
        this._changeListeners = new Map();

        this._hydrate_defaults_if_needed();

    }

    _hydrate_defaults_if_needed() {
        for (const entry of this.params) {
            if (!this.scope.hasStateValue(entry.id)) {
                this.scope.setStateValue(entry.id, entry.default, entry.default, true, true);
            }
        }
    }

    has(key) {
        return this.paramsDict.has(key);
    }

    get(key) {
        if (!this.has(key)) {
            throw new Error('unknown parameter key: ' + key);
        }
        return this.scope.stateValue(key);
    }

    set(key, newValue) {
        this.scope.setStateValue(key, newValue, this.paramsDict.get(key).default);
    }

    currentParams() {
        return new Map(this.params.map((entry) => [entry.id, this.get(entry.id)]));
    }

    addChangeListener(onChangeCallback) {
        this._listenerIdCounter += 1;
        const listener = {
            id: this._listenerIdCounter,
            fn: onChangeCallback
        };
        this._changeListeners.set(listener.id, listener);

        if (!this._scopeChangeListener) {
            const thisMap = this;
            this._scopeChangeListener = this.scope.addChangeListener(() => {
                thisMap.fireChangeListeners();
            });
        }

        return listener;
    }

    removeChangeListener(listener) {
        this._changeListeners.delete(listener.id);
        if (!this._changeListeners.size) {
            this.scope.removeChangeListener(this._scopeChangeListener);
            this._scopeChangeListener = null;
        }
    }

    fireChangeListeners() {
        this._hydrate_defaults_if_needed();
        for (const [listenerId, listener] of this._changeListeners) {
            listener.fn();
        }
    }

    toString() {
        const currentParamsJSON = JSON.stringify(Object.fromEntries(this.currentParams()));
        return "[ScopedStateParamMap scope=" + JSON.stringify(this.scope.scope) + ", currentParams="+ currentParamsJSON +"]";
    }
}


function useScopedStateParam(scopedState, name, initialValue, onChangeListener) {
    const [paramValue, $paramValue] = useState(initialValue);
    const [actualValue, $actualValue] = useState(initialValue);

    useEffect(() => {
        const newValue = paramValue;
        if (actualValue !== newValue) {
            if (onChangeListener) {
                onChangeListener(actualValue, newValue);
            }
            $actualValue(newValue);
        }
    }, [paramValue]);

    useEffect(() => {
        const listener = scopedState.addChangeListener(function () {
            const newValue = scopedState.stateValue(name);
            if (actualValue !== newValue) {
                $actualValue(newValue);
                $paramValue(newValue);
            }
        });

        const cleanup = function () {
            scopedState.removeChangeListener(listener);
        };
        return cleanup;
    });

    return [actualValue, $paramValue]
}


export function useScopedStateParamMap(paramMap) {
    const [params, $params] = useState(paramMap.currentParams());

    useEffect(() => {
        // manage listening
        const listener = paramMap.addChangeListener(function () {
            const updatedParams = paramMap.currentParams();

            if (StringifyMap(params) !== StringifyMap(updatedParams)) {
                $params(updatedParams);
                console.log('useScopedStateParamMap listener fired, found update', paramMap.toString(), StringifyMap(params), StringifyMap(updatedParams));
            } else {
                console.log('useScopedStateParamMap noop history change, skipping - this is wasteful and probably hides bugs');
            }
        });

        const thisParamMap = paramMap;
        const cleanup = function () {
            thisParamMap.removeChangeListener(listener);
        };
        return cleanup;
    }, [paramMap]);

    return [params, paramMap];
}

