import { observable, computed, action, flow, autorun, reaction } from "mobx"
import { computedFn } from "mobx-utils"

import { EncyclopediaGlobalState } from "../../../../endpoints.autogen";
import { cleanOps, mergeOps, removeInceptionV1Heads } from "../diagrams/munge";
import { extract } from "../diagrams/layout";
import { LoadingStatus } from "./Store";

import { Model } from "./ModelStore";
import { Op } from "./OpStore";
import { Unit } from "./UnitStore";

import { APIModelsInterface } from "./APIInterfaces";
import ModelTechniqueStore from "./ModelTechniqueStore";
import OpTechniqueStore from "./OpTechniqueStore";
import { baseTechnique } from "../techniques/Technique";

export default class MicroscopeStore {

    //
    // Initialization
    //
    @observable
    panels = {
        microscope: {
            opened: false
        },
        model: {
            opened: false,
            techniqueStore: new ModelTechniqueStore()
        },
        op: {
            opened: false,
            techniqueStore: new OpTechniqueStore()
        },
        unit: {
            opened: false
        }
    }

    constructor() {
        // Load the list of available models automatically.
        this.loadModels();
        // this.panels.model.techniqueStore.techniqueOptions

        // model defaul was deep dream
        // op default was feature_vis, channel

        // https://microscope.openai.com/models/inceptionv1?models.caricature.image=flowers&models.technique=caricature
        // https://microscope.openai.com/models/inceptionv1/mixed5b_0?models.op.feature_vis.type=neuron&models.technique=caricature
        // https://microscope.openai.com/models/inceptionv1/mixed5b_0?models.op.technique=caricature
        // https://microscope.openai.com/models/inceptionv1/mixed5b_0?models.op.feature_vis.type=neuron


        console.log(">>constructor")
        // autorun(reaction => {

        // });

        // const r = reaction(
        //     () => this.panels.model.techniqueStore.techniqueOptions.value,
        //     value => {
        //         console.log(">>reaction", `suppressUrlUpdate: ${this.suppressUrlUpdate}`);
        //         if (!this.suppressUrlUpdate) {
        //             this.setUrlFromStore()
        //         }
        //     }
        // )
        // r();
    }

    //
    // Location
    //

    // @observable
    r = () => { }

    @action
    createReaction() {
        this.deleteReaction();
        const r = reaction(
            () => {
                let observablesToTrack = [
                    this.panels.model.techniqueStore.techniqueOptions.value,
                    this.panels.op.techniqueStore.techniqueOptions.value,
                ];
                for (const param of this.panels.model.techniqueStore.techniqueOptions.value.params) {
                    observablesToTrack.push(param.options.value);
                }
                for (const param of this.panels.op.techniqueStore.techniqueOptions.value.params) {
                    observablesToTrack.push(param.options.value);
                }
                return observablesToTrack;
            },
            values => {
                console.log(">>reaction", `suppressUrlUpdate: ${this.suppressUrlUpdate}`);
                // if (!this.suppressUrlUpdate) {
                this.setUrlFromStore()
                // }
            }
        )
        this.r = r;
    }

    @action
    deleteReaction() {
        this.r();
        this.r = () => { };
    }

    @action
    setStoreFromUrl() {
        console.log(">>setStoreFromUrl")
        // this.suppressUrlUpdate = true;
        const location = this.history.location;
        const searchParams = new URLSearchParams(location.search);
        // We have to set the techniques first
        for (const [key, value] of searchParams) {
            if (key === "models.op.technique") {
                this.panels.op.techniqueStore.techniqueOptions.$selectedId(value);
            } else if (key === "models.technique") {
                this.panels.model.techniqueStore.techniqueOptions.$selectedId(value);
            }
        }
        // Now we can set the params
        for (const [key, value] of searchParams) {
            const opPrefix = "models.op."
            const modelPrefix = "models."
            if (key.startsWith(opPrefix)) {
                if (key !== "models.op.technique") {
                    const techniqueAndParam = key.replace(opPrefix, "");
                    const parts = techniqueAndParam.split(".");
                    const techniqueKey = parts[0];
                    const paramKey = parts[1];
                    const technique = this.panels.op.techniqueStore.techniqueOptions.value as baseTechnique;
                    const foundParam = technique.params.find(param => param.id === paramKey);
                    if (foundParam) {
                        foundParam.options.$selectedId(value);
                    }
                }
            } else if (key.startsWith(modelPrefix)) {
                if (key !== "models.technique") {
                    const techniqueAndParam = key.replace(modelPrefix, "");
                    const parts = techniqueAndParam.split(".");
                    const techniqueKey = parts[0];
                    const paramKey = parts[1];
                    const technique = this.panels.model.techniqueStore.techniqueOptions.value as baseTechnique;
                    const foundParam = technique.params.find(param => param.id === paramKey);
                    if (foundParam) {
                        foundParam.options.$selectedId(value);
                    }
                }
            }
        }
        // this.suppressUrlUpdate = false;

    }

    @action
    setUrlFromStore() {
        console.log(">>setUrlFromStore", `supressingUpdate: ${this.suppressUrlUpdate}`)
        // if (!this.suppressUrlUpdate) {
        const searchParams = [];
        if (this.history) {
            const currentLocation = this.history.location.pathname;
            const currentSearchString = this.history.location.search

            if (this.panels.model.opened) {
                const modelTechnique = this.panels.model.techniqueStore.techniqueOptions.value;
                searchParams.push(["models.technique", modelTechnique.id]);
                console.log(">>setUrlFromStore", modelTechnique.id)
                for (const param of modelTechnique.params) {
                    searchParams.push([`models.${modelTechnique.id}.${param.id}`, param.options.value]);
                }
            }
            if (this.panels.op.opened) {
                const opTechnique = this.panels.op.techniqueStore.techniqueOptions.value;
                searchParams.push(["models.op.technique", opTechnique.id]);
                for (const param of opTechnique.params) {
                    searchParams.push([`models.op.${opTechnique.id}.${param.id}`, param.options.value]);
                }
            }
            searchParams.sort((a, b) => a[0].localeCompare(b[0]))
            const searchString = searchParams.length ? "?" + searchParams.map(p => p.join("=")).join("&") : "";
            console.log(">>setUrlFromStore", searchString, currentSearchString)
            if (currentSearchString !== searchString) {
                this.history.replace({
                    pathname: currentLocation,
                    search: searchString
                });
            }
        }
        // }
    }

    history: any;

    @action
    $history(value: any) {
        console.log(">>$history")
        this.history = value;
        // this.suppressUrlUpdate = true;
        this.setStoreFromUrl();
        this.createReaction();

        // this.onNavigate(this.history.location);
        const unlisten = value.listen((location: any, action: any) => {
            this.onNavigate(location, action);
        });
    }

    suppressUrlUpdate = false

    @action
    onNavigate(location: any, action?: any) {
        console.log(">>onNavigate");
        this.setUrlFromStore();
        // this.suppressUrlUpdate = true;
        // console.log("!!onNavigate", this, this.panels);
        // const searchParams = new URLSearchParams(location.search);
        // for (const [key, value] of searchParams) {
        //     if (key === "models.technique") {
        //         this.panels.model.techniqueStore.techniqueOptions.$selectedId(value);
        //     }
        //     if (key === "models.op.technique") {
        //         this.panels.op.techniqueStore.techniqueOptions.$selectedId(value);
        //     }
        // }
        // this.suppressUrlUpdate = false;
    }

    @observable
    baseUrl: string | null = null;

    @action
    $baseUrl(value: string) {
        this.baseUrl = value;
    }



    // Sets the current path params, modelId, opId, and unitId.
    @action
    $location(modelId: string | null | undefined, opId: string | null | undefined, unitId: string | null | undefined) {
        console.log(">>$location")
        if (modelId === null || modelId === undefined) {
            this.modelId = null
        } else {
            const foundModel = this.getModelById(modelId);
            if (!foundModel) {
                if (this.modelsStatus === LoadingStatus.success) {
                    throw new Error("Can't find model in models with id: " + modelId);
                } else {
                    // If we can't find the model and the list of available
                    // models hasn't loaded yet let's create a stub.
                    // Once the list loads, this object will get hydrated.
                    const model = new Model(modelId);
                    this.addModel(model);
                }
            }
            this.modelId = modelId;
        }
        if (opId === null || opId === undefined) {
            this.opId = null;
        } else {
            this.opId = opId;
        }
        if (unitId === null || unitId === undefined) {
            this.unitId = null;
        } else {
            this.unitId = parseInt(unitId, 10);
        }
        this.panels.microscope.opened = this.modelId === null;
        this.panels.model.opened = this.modelId !== null && this.opId === null;
        this.panels.op.opened = this.opId !== null && this.unitId === null;
        this.panels.unit.opened = this.unitId !== null;

        this.setUrlFromStore();
    }

    // @computed
    // get pageTitle() {
    //     let title = "";
    //     if (!this.model) {
    //         title = `OpenAI Microscope / Models`;
    //     } else {
    //         if (!this.op) {
    //             title = `${this.model.title} — OpenAI Microscope / Models`;
    //         } else {
    //             if (!this.unit) {
    //                 title = `${this.model.title} / ${this.op.title} — OpenAI Microscope / Models`;
    //             } else {
    //                 title = `${this.model.title} / ${this.op.title} / ${this.unit.title} — OpenAI Microscope / Models`;
    //             }
    //         }
    //     }
    //     return title;
    // }


    //
    // Models
    //

    @observable
    unsortedModels: Model[] = [];

    @computed get models() {
        return this.unsortedModels.slice().sort((a: any, b: any) => {
            return a.order - b.order;
        });
    }

    @observable
    modelsStatus: LoadingStatus = LoadingStatus.none;

    // For more about mobx flows:
    //https://mobx.js.org/best/actions.html#flows
    loadModels = flow(function* () {
        this.modelsStatus = LoadingStatus.pending;
        try {
            const json = (yield (new EncyclopediaGlobalState()).models()) as APIModelsInterface;
            const models = json.models;

            //TODO This is a hack, remove if we want this model
            const filteredModels = models.filter((m: any) => m.id !== "resnetv2_152_slim");

            // Remap the API output to the flatter structure I want.
            const modelMetadata = filteredModels.map((m: any) => {
                m.metadata.ops = [];
                m.metadata.id = m.id;
                m.metadata.graphStatus = LoadingStatus.none;
                return m.metadata;
            });
            modelMetadata.forEach(modelData => {
                const model = new Model(modelData.id, modelData);
                this.addModel(model);
            });
            this.modelsStatus = LoadingStatus.success;

        } catch (error) {
            console.error("Error loading models.");
            this.modelsStatus = LoadingStatus.error;
        }
    });

    // Adding a model to the model list.
    // If we already have the same id in the list, we hydrate the data
    @action
    addModel(model: Model) {
        const foundModel: Model | undefined = this.unsortedModels.find((candidateModel: Model) => candidateModel.id === model.id);
        if (foundModel) {
            foundModel.hydrate(model);
        } else {
            this.unsortedModels.push(model);
        }
    }

    getModelById(modelId: string): Model | undefined {
        const model: Model | undefined = this.unsortedModels.find((model: Model) => model.id === modelId);
        return model;
    }


    //
    // Model
    //

    @observable
    modelId: string | null = null;

    @action
    $modelId(value: string) {
        this.modelId = value;
    }

    @computed({ keepAlive: true })
    get model(): Model | undefined {
        const found = this.modelId ? this.getModelById(this.modelId) : undefined;
        return found ? found : undefined;
    }

    //
    // Op
    //

    @observable
    opId: string | null = null;

    @action
    $opId(value: string) {
        this.opId = value;
    }

    @computed({ keepAlive: true })
    get op(): Op | undefined {
        const found = this.opId ? this.getOpById(this.opId) : undefined;
        return found ? found : undefined;
    };

    getOpById(opId: string): Op | undefined {
        if (this.model) {
            const op: Op | undefined = this.model.ops.find((op: Op) => op.id === opId);
            return op;
        } else {
            return undefined
        }
    }

    //
    // Unit
    //

    @observable
    unitId: number | null = null;

    @action
    $unitId(value: number) {
        this.unitId = value;
    }

    @computed({ keepAlive: true })
    get unit(): Unit | undefined {
        const found = this.unitId !== null ? this.getUnitById(this.unitId) : undefined;
        return found ? found : undefined;
    }

    getUnitById(unitId: number): Unit | undefined {
        if (this.op) {
            const unit: Unit | undefined = this.op.units.find((unit: Unit) => unit.id === unitId);
            return unit;
        } else {
            return undefined;
        }
    }


    //
    // Utils
    //

    // A useful way for any component to kick to the rendering system to force
    // all the panels to measure their dimensions. Useful for things that
    // require measuring that don't involve a window resize.
    @observable
    measure: number = Date.now()

    @action
    $measure = (value: number) => {
        this.measure = value;
    }

}