import React, { } from 'react';
import ReactDOM from 'react-dom';

export default function ({ target, children }) {
    let left = 0;
    let top = 0;
    if (target) {
        const location = target.getBoundingClientRect();
        left = location.left;
        top = location.top;
    }
    const container = document.getElementById("tooltip");
    if (container) {
        return ReactDOM.createPortal(
            (<div style={{ position: "fixed", zIndex: 1e6, top: `${top}px`, left: `${left}px`, pointerEvents: "none" }}>
                {children}
            </div>),
            container
        );

    } else {
        return null;
    }

}