import { technique, unitTechnique, opTechnique, techniqueTarget, isOpTechniqueType, isUnitTechniqueType } from "./Technique"
import Caricature from "./Caricature";
import FeatureVis from "./FeatureVis";
import DatasetExamples from "./DatasetExamples";
import DeepDream from "./DeepDream";
import Weights from "./Weights";
import TextFeatureVis from "./TextFeatureVis";
import SyntheticTuning from "./SyntheticTuning";


function sortTechniqueList(a: technique, b: technique) {
    if (a.rank === undefined || b.rank === undefined) {
        if (a.rank === undefined && b.rank === undefined) {
            return a.title.localeCompare(b.title);
        } else if (a.rank === undefined && b.rank !== undefined) {
            return 1;
        } else if (b.rank === undefined && a.rank !== undefined) {
            return -1;
        } else {
            return 0;
        }
    } else {
        if (a.rank === b.rank) {
            return a.title.localeCompare(b.title);
        } else {
            return b.rank > a.rank ? 1 : -1;
        }
    }
}

export const allTechniques = [
    Caricature, FeatureVis, DeepDream, DatasetExamples, TextFeatureVis, SyntheticTuning
].sort(sortTechniqueList)

function getTechniquesByDisplay(target: techniqueTarget): technique[] {
    const found = allTechniques.filter((t) => {
        return t.display[target]
    });
    return found ? found : [];
}

export let opTechniques: opTechnique[] = []
allTechniques.forEach((t) => {
    if (isOpTechniqueType(t)) {
        opTechniques.push(t)
    }
});

export let unitTechniques: unitTechnique[] = []
allTechniques.forEach((t) => {
    if (isUnitTechniqueType(t)) {
        unitTechniques.push(t)
    }
});

type techniquesList = {
    model: technique[],
    op: technique[],
    unit: technique[],
}

export const techniques: techniquesList = {
    model: getTechniquesByDisplay(techniqueTarget.model),
    op: getTechniquesByDisplay(techniqueTarget.op),
    unit: getTechniquesByDisplay(techniqueTarget.unit),
}


