import React, { useEffect, useState } from "react";
import { DatasetExamplesEndpoints } from "../../../../endpoints.autogen";
import { observer } from "mobx-react";
import { APIDatasetExamplesInterface, APIDatasetExamplesChannelInterface } from "../stores/APIInterfaces"
import Picture from "../../../../components/Picture";
import SpriteGrid from "../../../../components/SpriteGrid";
import { unitTechnique, techniqueTarget, unitTechniqueRenderProps } from "./Technique";
import { Model } from "../stores/ModelStore";
import ListChooser from "../../../../components/controls/ListChooser";
import { Options } from "../stores/Store"


// This is the visible component for the thumbnail display of this technique.
const Thumbnail = observer(({ model, op, unit, params }: unitTechniqueRenderProps) => {

    const [leafData, $leafData] = useState< string | undefined>();
    const [leafDataYFCC, $leafDataYFCC] = useState< string | undefined>();

    const sizes = [20, 30, 40, 60]
    const i = 0
    function set() {
        $leafData(undefined)
        new DatasetExamplesEndpoints()
            .op_dataset_example_crops(model.id, op.id, "imagenet")
            .then(function (result: any) {
                if (result) {
                    console.log("dataset", result)
                    $leafData(result.examples[unit.id].spritesheet[i].url)
                    // fetch(result.examples[unit.id]['url'])
                    // .then( (response) => response.json())
                    // .then( (responseJson) => $leafData(responseJson))
                } 
            });

        $leafDataYFCC(undefined)
        new DatasetExamplesEndpoints()
            .op_dataset_example_crops(model.id, op.id, "yfcc")
            .then(function (result: any) {
                if (result) {
                    $leafDataYFCC(result.examples[unit.id].spritesheet[i].url)
                } 
            });

    }

    useEffect(() => {
        $leafData(undefined);
        $leafDataYFCC(undefined);
        if (model && op && unit) {
            set();
        }
    }, [model, op, unit, params]);

    if (leafData) {
        return <div> {SpriteGrid([leafData], sizes[i]*10*2, sizes[i]*10*2, "", [10,10], 9)} 
                     <h3 style={{marginTop: "20px"}}>Dataset: Yahoo Flickr Creative Commons</h3> {SpriteGrid([leafDataYFCC], sizes[i]*10*2, sizes[i]*10*2)}</div>
    } else {
        return <div></div>
    }

    // const [leafData, $leafData] = useState<APIDatasetExamplesChannelInterface | undefined>();
    // const [channelData, $channelData] = useState<APIDatasetExamplesInterface | undefined>();
    // const [datasetName, $datasetName] = useState<string | undefined>();

    // function set(ds: string, leafData: any) {

    //     $leafData(undefined)
    //     new DatasetExamplesEndpoints()
    //         .op_dataset_example_crops(model.id, op.id, ds)
    //         .then(function (result: APIDatasetExamplesInterface) {
    //             if (result) {
    //                 fetch(result.examples[unit.id]['url'])
    //                 .then((response) => response.json())
    //                 .then( (responseJson) => $leafData(responseJson))
    //                 $channelData(result);
    //             } else {
    //                 $channelData(undefined); // not in cache
    //             }
    //         });

    // }

    // useEffect(() => {
    //     $leafData(undefined);
    //     $datasetName("imagenet")
    //     if (model && op && unit) {
    //         set("imagenet", $leafData);
    //     }
    // }, [model, op, unit, params]);
    
    // if (leafData && channelData) {
    //     const i = 0
    //     const spatial_locations = channelData.spatial_locations[i]
    //     const x = 100*(leafData.spatials[i] % spatial_locations)/spatial_locations
    //     const y = 100*Math.floor(leafData.spatials[i]/spatial_locations)/spatial_locations
    //     const w = 100/spatial_locations
    //     return <div style={{width: "100%"}}>
    //             <div style={{"position": "absolute", "top": y+"%", "left": x+"%", "width": w+"%", "height": w+"%", "border": "0.1px solid rgb(0,0,0,0.1)", "boxShadow": "0px 0px 100px 154px rgb(220, 220, 220, 0.8)"}}></div>
    //             <img style={{objectFit: "scale-down"}} src={channelData.base_url + leafData.urls[i] + ".jpg"}></img>
    //             </div>
    // }
    // else {
    //     return <p></p>
    // }
    // return <p></p>
})


// This is the visible component for the main display of this technique.
const Main = observer(({ model, op, unit, params }: unitTechniqueRenderProps) => {

    const [leafData, $leafData] = useState< string | undefined>();
    const [leafDataYFCC, $leafDataYFCC] = useState< string | undefined>();

    const sizes = [20, 30, 40, 60]
    const i = 2
    function set() {
        $leafData(undefined)
        new DatasetExamplesEndpoints()
            .op_dataset_example_crops(model.id, op.id, "imagenet")
            .then(function (result: any) {
                if (result) {
                    console.log("dataset", result)
                    $leafData(result.examples[unit.id].spritesheet[i].url)
                    // fetch(result.examples[unit.id]['url'])
                    // .then( (response) => response.json())
                    // .then( (responseJson) => $leafData(responseJson))
                } 
            });

        $leafDataYFCC(undefined)
        new DatasetExamplesEndpoints()
            .op_dataset_example_crops(model.id, op.id, "yfcc")
            .then(function (result: any) {
                if (result) {
                    $leafDataYFCC(result.examples[unit.id].spritesheet[i].url)
                } 
            });

    }

    useEffect(() => {
        $leafData(undefined);
        $leafDataYFCC(undefined);
        if (model && op && unit) {
            set();
        }
    }, [model, op, unit, params]);

    if (leafData) {
        return <div> <h3>Dataset: Imagenet</h3> {SpriteGrid([leafData], sizes[i]*10*2, sizes[i]*10*2)} 
                     <h3 style={{marginTop: "20px"}}>Dataset: Yahoo Flickr Creative Commons</h3> {SpriteGrid([leafDataYFCC], sizes[i]*10*2, sizes[i]*10*2)}</div>
    } else {
        return <div></div>
    }

    // const [leafData, $leafData] = useState<APIDatasetExamplesChannelInterface | undefined>();
    // const [channelData, $channelData] = useState<APIDatasetExamplesInterface | undefined>();
    // const [datasetName, $datasetName] = useState<string | undefined>();
    
    // if (leafData && channelData) {
    //     // const spatial_locations = channelData.spatial_locations[0]
    //     // const width      = 224/spatial_locations
    //     // var icon_width   = 3*width
    //     // const scale = Math.sqrt(spatial_locations) + 1
    //     // icon_width = Math.min(scale % 2 == 0 ? scale + 1: scale, 7)*width

    //     // const num_cols   = Math.round(700/(icon_width + 10))
    //     // const border     = (224 - icon_width)

    //     // const num_imgs = leafData.urls.length
    //     // const options = new Options(["a","b"], 2)

    //     return <div>Hello</div>
    //     // return <div>
    //     //        <div> {['imagenet'].map((name) => <span style={{
    //     //                     backgroundColor: (name == datasetName) ? "rgb(220,220,220)": "rgb(245,245,245)",
    //     //                     padding: "2px 0px 0px 5px",
    //     //                     position: "relative",
    //     //                     marginRight: "1px",
    //     //                     top: "0px",
    //     //                     borderTopLeftRadius: "4px",
    //     //                     borderTopRightRadius: "4px",
    //     //                     width: "100px",
    //     //                     display: "inline-block",
    //     //                     borderRight: "1px solid rgb(220,220,220)",
    //     //                     cursor: "default",
    //     //                     fontSize: "14px"}}
    //     //                     onClick={ () => {console.log("Selected: ", name); $datasetName(name); set(name, $datasetName)} }>
    //     //                         {name}
    //     //                     </span>)}
    //     //        </div>
    //     //        <div style={{display: "flex",
    //     //                     flexFlow: "row wrap",
    //     //                     width: (num_cols*(icon_width + 5*2)) + "px",
    //     //                     backgroundImage: 'url("http://microscope.openai.com/transparency-indicator.png")',
    //     //                     borderTopLeftRadius: "0px",
    //     //                     borderTopRightRadius: "4px",
    //     //                     borderBottomLeftRadius: "4px",
    //     //                     borderBottomRightRadius: "4px",
    //     //                     border: "solid 2px rgb(220,220,220)",
    //     //                     borderTop: "solid 2px rgb(220,220,220)",
    //     //                     padding:"3px"}}>
    //     // {[...Array(127).keys()].map((i) =>  <div style={{width: (224-border) + "px", 
    //     //                                                height:(224-border) + "px",
    //     //                                                border: "1px solid rgba(0, 0, 0, 0.2)",
    //     //                                                borderRadius: "4px",
    //     //                                                margin: "4px",
    //     //                                                overflow: "hidden",
    //     //                                                position: "relative"}}>
    //     //                                     <img style={{position: "relative",
    //     //                                                  left: (-width/2 + 112 - border/2 - 224*(leafData.spatials[i] % spatial_locations)/spatial_locations) + "px",
    //     //                                                  top: (-width/2 + 112 - border/2 - 224*Math.floor(leafData.spatials[i]/spatial_locations)/spatial_locations) + "px"}}
    //     //                                          src={channelData.base_url + leafData.urls[i] + ".jpg"} alt={"" + leafData.values[i]}></img>
    //     //                                     <div style={{width: width + "px",
    //     //                                                  height: width +"px",
    //     //                                                  position: "absolute",
    //     //                                                  top: (224/2- border/2-width/2) + "px",
    //     //                                                  left: (224/2- border/2-width/2) + "px",
    //     //                                                  border: "1px solid rgba(0, 0, 0, 0.3)",
    //     //                                                  boxShadow: "0px 0px 14px 3px rgb(255, 255, 255, 0.5)"}}>
    //     //                                     </div>

    //     //                                   </div>)}
    //     // </div>
    //     // </div>

    // }

    // } else {

    //     return <div>
    //            <div> {['imagenet', 'imagenet', 'twitter1', 'twitter2', 'twitter3'].map((name) => <span style={{
    //                         backgroundColor: (name == datasetName) ? "rgb(220,220,220)": "rgb(245,245,245)",
    //                         padding: "2px 0px 0px 5px",
    //                         position: "relative",
    //                         marginRight: "1px",
    //                         top: "0px",
    //                         borderTopLeftRadius: "4px",
    //                         borderTopRightRadius: "4px",
    //                         width: "100px",
    //                         display: "inline-block",
    //                         borderRight: "1px solid rgb(220,220,220)",
    //                         fontSize: "14px"}}
    //                         onClick={ () => {console.log("Selected: ", name); $datasetName(name); set(name, $leafData); } }>
    //                             {name}
    //                         </span>)}
    //            </div>
    //            <div style={{display: "flex",
    //                         flexFlow: "row wrap",
    //                         width: "700px",
    //                         height: "30px",
    //                         backgroundImage: 'url("http://microscope.openai.com/transparency-indicator.png")',
    //                         borderTopLeftRadius: "0px",
    //                         borderTopRightRadius: "4px",
    //                         borderBottomLeftRadius: "4px",
    //                         borderBottomRightRadius: "4px",
    //                         border: "solid 2px rgb(220,220,220)",
    //                         borderTop: "solid 2px rgb(220,220,220)",
    //                         padding:"3px"}}>
                
    //             </div>
    //             </div>    
    //     }
    
});


// This are the necessary details for a technique component
const DatasetExamples: unitTechnique = {
    id: "dataset_examples",
    backendId: "lucid.dataset_examples",
    title: "Dataset Samples",
    rank: 5,
    Description: () => <>
        Pieces of images from the training dataset that result in the largest
        activations from the given unit.
    </>,
    unit: true,
    params: [

    ],
    display: {
        op: true,
        unit: true,
    },
    Thumbnail,
    Main,
    Footer: () => <>These images are cropped and downsized samples from the <a href="http://image-net.org/about-overview">ImageNet</a> research dataset. Unlike our other visualizations, they are not CC-BY-SA because they are derived from ImageNet.</>
}
export default DatasetExamples;



// DatasetExamples.techniqueType = "channel";
// DatasetExamples.thumbnailNaturalSize = { width: 225, height: 225 };
// DatasetExamples.params = [
// 	{
// 		id: "dataset",
// 		label: "Dataset",
// 		inputType: "radio",
// 		options: ["imagenet"],
// 		default: "imagenet",
// 	},
// ];
// DatasetExamples.Description = function () {
// 	return (
// 		<>
// 			Pieces of images from the training dataset that result in the largest
// 			activations from the given unit.
// 		</>
// 	);
// };
