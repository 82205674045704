import { Model } from "../stores/ModelStore"
import { Op } from "../stores/OpStore"
import { Unit } from "../stores/UnitStore"
import { Options } from "../stores/Store";

export enum techniqueTarget {
    model = "model",
    op = "op",
    unit = "unit"
}

export type opTechniqueRenderProps = {
    params?: any[],
    model: Model,
    op: Op,
}

export type unitTechniqueRenderProps = {
    params?: any[],
    model: Model,
    op: Op,
    unit: Unit,
}

export enum paramDataType {
    int,
    float,
    string
}

export type param = {
    id: string,
    title: string,
    rank?: number,
    splay?: boolean,
    hoist?: boolean,
    dataType: paramDataType,
    options: Options,
    suppressDisplay?: techniqueTarget[],
    chooserType?: chooserTypes
}

export enum chooserTypes {
    radio = "radio",
    dropdown = "dropdown",
    slider = "slider",
}

// This type is not used directly
export type baseTechnique = {
    // The id used in the url and internally in the frontend
    id: string,
    // The id used by the django backend.
    backendId: string,
    // The display name of the technique.
    title: string,
    // Used for sorting and ranking lists and chooser.
    rank?: number,
    // where in the app to display the component.
    // if we are showing you the target, we will use the Main component,
    // if not, we will use the Thumbnail component in a grid, list or graph.
    display: {
        [key in techniqueTarget]?: boolean
    },
    // A detailed description of the technique. Is a react component so that you
    // may include links, and othe markup.
    Description: React.FC,
    // Used to describe footnote type information about the technique, license
    // and that sort of thing.
    Footer?: React.FC,
    params: param[],

}

export type opTechnique = baseTechnique & {
    // The component used to display the technique when we are showing it
    // upstream from its target. For instance, when viewing an op, we will show
    // the thumbnail component for all `unit` targeted techniques.
    Thumbnail: React.FC<opTechniqueRenderProps>,
    // The component used to display the technique when we are viewing it at
    // its target. For instance, when viewing a unit, unit techniques will be
    // rendered as a Main component.
    Main: React.FC<opTechniqueRenderProps>,
    op: true,
}
export function isOpTechniqueType(toBeDetermined: opTechnique | unitTechnique): toBeDetermined is opTechnique {
    return (toBeDetermined as opTechnique).op === true;
}

export type unitTechnique = baseTechnique & {
    // The component used to display the technique when we are showing it
    // upstream from its target. For instance, when viewing an op, we will show
    // the thumbnail component for all `unit` targeted techniques.
    Thumbnail: React.FC<unitTechniqueRenderProps>,
    // The component used to display the technique when we are viewing it at
    // its target. For instance, when viewing a unit, unit techniques will be
    // rendered as a Main component.
    Main: React.FC<unitTechniqueRenderProps>,
    unit: true,
}
export function isUnitTechniqueType(tbd: opTechnique | unitTechnique): tbd is unitTechnique {
    return (tbd as unitTechnique).unit === true;
}

export type technique = opTechnique | unitTechnique;