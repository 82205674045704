import React, { } from "react";
import Picture from "./Picture";


export default function SpriteGrid(sprite_images: any[], spriteH: number, spriteW: number, alt = "", shape = [10, 10], n = 100) {
    const sprite = sprite_images[0];
    const [h, w] = [spriteH / shape[0], spriteW / shape[1]];
    const styles = [...Array(n).keys()].map((index) => {
        const xOffset = (index % shape[0]) * w;
        const yOffset = Math.floor(index / shape[1]) * h;
        return {
            height: h,
            width: w,
            objectPosition: `-${xOffset}px -${yOffset}px`,
            borderRadius: "2px",
            border: "solid 1px rgba(0, 0, 0, 0.2)",
            overflow: "hidden",
            objectFit: "none"            
        } as React.CSSProperties;
    });
    const sprites = styles.map((style, id) => <img style={style} src={sprite}></img>);

    return (
        <div
            className="spriteGrid"
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(auto-fill, ${w}px)`,
                gridAutoRows: `${h}px`,
                gap: "7px",
                padding: "7px",
                borderRadius: "2px",
                justifyContent: "flex-start",
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                overflowY: "scroll",
                backgroundImage: "url(/transparency-indicator.png)",
            }}
        >
            {sprites}
        </div>
    );
}