import React, {
    useEffect,
    useState
} from 'react';
import { configure } from "mobx";
import { observer } from "mobx-react";

import { useStores } from "./stores/context";
import { useRouteMatch, Route, useHistory } from "react-router-dom";
import MicroscopePanel from "./panels/MicroscopePanel";
import ModelPanel from "./panels/ModelPanel";
import OpPanel from "./panels/OpPanel";
import UnitPanel from "./panels/UnitPanel";
import { format } from "d3-format";
import styles from "./Microscope.module.css";
import { Error } from '../../Alert';

const f = format(",");

// Global configuration of Mobx.
// https://mobx.js.org/refguide/api.html#configure
configure({
    enforceActions: "observed",
    // observableRequiresReaction: true,
    // computedRequiresReaction: true,
    // reactionRequiresObservable: true,
});

const Microscope = observer(() => {

    const match = useRouteMatch();
    const { microscopeStore } = useStores();

    return (
        <Route
            path={[
                `${match.url}/:modelId/:opId/:unitId`,
                `${match.url}/:modelId/:opId`,
                `${match.url}/:modelId`,
                `${match.url}`,
            ]}
            render={(params) => {
                const localMatch = params.match;
                microscopeStore.$baseUrl(match.url);
                microscopeStore.$location(localMatch.params.modelId, localMatch.params.opId, localMatch.params.unitId);
                return (<div className={styles.panels}>
                    <MicroscopePanel
                        baseUrl={microscopeStore.baseUrl}
                        opened={microscopeStore.panels.microscope.opened}
                    ></MicroscopePanel>
                    <ModelPanel
                        baseUrl={`${microscopeStore.baseUrl}/${microscopeStore.modelId}`}
                        opened={microscopeStore.panels.model.opened}
                    ></ModelPanel>
                    <OpPanel
                        baseUrl={`${microscopeStore.baseUrl}/${microscopeStore.modelId}/${microscopeStore.opId}`}
                        opened={microscopeStore.panels.op.opened}
                    ></OpPanel>
                    <UnitPanel
                        baseUrl={`${microscopeStore.baseUrl}/${microscopeStore.modelId}/${microscopeStore.opId}/${microscopeStore.unitId}`}
                        opened={microscopeStore.panels.unit.opened}
                    ></UnitPanel>

                </div>);
            }}
        ></Route>
    );
});
export default Microscope;
