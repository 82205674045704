import React from 'react';

export default function UnExpand() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32">
            <polyline points="21 1.5 30.5 1.5 30.5 11" />
            <polyline points="11 30.5 1.5 30.5 1.5 21" />
            <line x1="19" y1="13" x2="30.5" y2="1.5" />
            <line x1="2" y1="30" x2="13.5" y2="18.5" />
        </svg>
    );
}