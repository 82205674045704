// third_party
import React, {
    useEffect,
    useState,
    forwardRef,
} from 'react';

import { useStores } from "../stores/context";
import { observer } from "mobx-react";
import { NavLink as Link } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import { format } from "d3-format";
// components
import Panel from "./Panel";
import styles from "./OpPanel.module.css";
import textStyles from "../../../../components/TextStyles.module.css";
// helpers
import useContainerDimensions from "../../../../hooks/useContainerDimensions";
import { InProgressBanner, NotCollectedBanner } from "../../../../components/Banners";
import { TechniqueParamsWrapper, useTechniqueParams } from "../../../../helpers/SummaryHelpers";
import ListChooser from "../../../../components/controls/ListChooser";
import { isOpTechniqueType, isUnitTechniqueType, techniqueTarget, chooserTypes } from "../techniques/Technique";
import { Error } from "../../../Alert"

// Number formatter
const f = format(",");

type OpPanelProps = {
    baseUrl: string,
    opened: boolean,
    scopedState: any,
}
const OpPanel = observer(({ baseUrl, opened, scopedState }: OpPanelProps) => {


    const { opViewStore, opTechniqueStore, microscopeStore } = useStores();
    const { op, measure } = microscopeStore;

    const urlParts = baseUrl.split("/");
    const closeUrl = urlParts.slice(0, -1).join("/");

    return (
        <>
            {op ? (

                < Panel
                    opened={opened}
                    openUrl={baseUrl}
                    closeUrl={closeUrl}
                    onStateChange={() => { microscopeStore.$measure(Date.now()) }}
                    title={op.title}
                    sidebar={
                        <OpSidebar baseUrl={baseUrl} opened={opened} measure={measure} />
                    }
                    main={
                        <OpMain baseUrl={baseUrl} opened={opened} measure={measure} />
                    }
                    settings={
                        <div>
                            <p>
                                Type: {op.type}<br />
                                Channels: {f(op.channels)}<br />
                                Convolution: {JSON.stringify(op.convSize)}<br />
                            </p>
                            <hr />
                            <div>
                                <h4>Technique</h4>
                                <ListChooser options={opTechniqueStore.techniqueOptions} />
                                <p className={textStyles.caption}><opTechniqueStore.technique.Description /></p>
                            </div>
                            <hr />
                            {opTechniqueStore.technique.params.length ? (
                                <>
                                    <div>
                                        <h4>Params</h4>
                                        {opTechniqueStore.technique.params.map((param) => {

                                            return (
                                                <>
                                                    {(param.suppressDisplay && param.suppressDisplay.includes(techniqueTarget.op)) ?
                                                        null : (
                                                            <>
                                                                <h5>{param.title}</h5>
                                                                <ListChooser options={param.options} type={param.chooserType ? param.chooserType : chooserTypes.radio} />
                                                            </>
                                                        )}
                                                </>
                                            )
                                        })}
                                    </div>
                                    <hr />
                                </>
                            ) : null}
                            <div>
                                {!isOpTechniqueType(opTechniqueStore.technique) ? (
                                    <>
                                        <h4>View</h4>
                                        <h5>Image Size</h5>
                                        <ListChooser options={opViewStore.thumbnailSizeOptions} type={chooserTypes.slider} />
                                        <h5>Resize Behavior</h5>
                                        <ListChooser options={opViewStore.imageResizeBehaviorOptions} type={chooserTypes.radio} />
                                    </>
                                ) : null}
                            </div>
                        </div>
                    }
                />
            ) : <div data-status="closed"></div>}
        </>
    );
});
export default OpPanel;

//
// Main
//

type OpMainProps = {
    baseUrl: string,
    opened: boolean,
    measure: number,
}
const OpMain = observer(({ baseUrl, opened, measure }: OpMainProps) => {
    const { microscopeStore, opTechniqueStore } = useStores();
    const { model, op } = microscopeStore;
    const { technique } = opTechniqueStore

    // TODO: this seems like it should be in the store.
    const channels = microscopeStore.op?.units ? microscopeStore.op.units.map(c => {
        return {
            url: `${baseUrl}/${c.id}`,
            op,
            model,
            ...c
        }
    }) : [];

    if (model && op) {
        if (isOpTechniqueType(technique)) {
            return <div className={styles.opTechnique}>
                <technique.Main model={model} op={op} />
            </div>
        } else {
            return <MainGrid
                channels={channels}
                opened={opened}
                measure={measure}
            />
        }
    } else {
        return <Error message={"No model or op."} />
    }


})


type MainGridProps = {
    channels: any[],
    opened: boolean,
    measure: number
}
const MainGrid = observer(({ channels, opened, measure }: MainGridProps) => {

    const { opViewStore, microscopeStore, opTechniqueStore } = useStores();
    const { model, op } = microscopeStore;
    const { thumbnailSize, imageResizeBehavior } = opViewStore;
    const { technique } = opTechniqueStore;

    const [containerRef, containerDimensions] = useContainerDimensions([opened, technique, measure]);

    const gap = Math.max(4, Math.min(12, Math.round(thumbnailSize / 12)));
    const titleSize = Math.max(9, Math.min(13, Math.round(thumbnailSize / 15)));
    const height = containerDimensions ? containerDimensions.height : 10;
    const numColumns = containerDimensions ? Math.floor((containerDimensions.width - 60 + gap) / (thumbnailSize + gap)) : 1;
    const numRows = Math.ceil(channels.length / numColumns);

    let columnData: any = [];
    for (let r = 0; r < numRows; r++) {
        columnData[r] = channels.slice(r * numColumns, r * numColumns + numColumns)
    }
    columnData.thumbnailSize = thumbnailSize;

    type ChannelRowMainProps = {
        data: any,
        index: number,
        style: any
    }
    function ChannelRowMain({ data, index, style }: ChannelRowMainProps) {
        const thumbnailSize = data.thumbnailSize;
        const channels = data[index];
        return (
            <div
                style={{ ...style, width: channels.length * (thumbnailSize + gap) - gap, paddingTop: gap / 2, paddingBottom: gap / 2 }}
                className={styles.channelRowMain}
            >
                {model && op ? (
                    <>
                        {channels.map((channel: any) => {
                            return (
                                <Link key={channel.id} to={channel.url} style={{ width: thumbnailSize, height: thumbnailSize }} className={styles.channelIcon}>
                                    <span style={{ fontSize: titleSize }}>{channel.title}</span>
                                    {technique && isUnitTechniqueType(technique) ? (
                                        <technique.Thumbnail model={model} op={op} unit={channel} />
                                    ) : null}
                                </Link>
                            )
                        })}
                    </>
                ) : null}
            </div>
        );
    }
    // Render
    if (op) {
        // if (false) {
        //     return <NotCollectedBanner component={technique.Component} op={op} style={{ margin: "var(--side-margin)" }} />;
        // } else if (false) {
        //     return <InProgressBanner component={technique.Component} op={op} style={{ margin: "var(--side-margin)" }} />
        // } else {
        return (
            <div className={[styles.channelListMain, imageResizeBehavior === "scale" ? styles.resize : null].join(" ")} >
                <div className={styles.channelGrid} ref={containerRef}>
                    <List
                        height={height}
                        itemData={columnData}
                        itemCount={columnData.length}
                        itemSize={thumbnailSize + gap}
                        overscanCount={3}
                    >
                        {ChannelRowMain}
                    </List>
                </div>
            </div>
        );
        // }

    } else {
        return null;
    }
});


//
// Sidebar
//

const sidebarRowMargins = { top: 3, bottom: 3 };

type OpSidebarProps = {
    baseUrl: string,
    opened: boolean,
    measure: number,
}
const OpSidebar = observer(({ baseUrl, opened, measure }: OpSidebarProps) => {
    const { microscopeStore, opTechniqueStore } = useStores();
    const { model, op } = microscopeStore;
    const { technique } = opTechniqueStore

    // TODO: this seems like it should be in the store.
    const channels = microscopeStore.op?.units ? microscopeStore.op.units.map(c => {
        return {
            url: `${baseUrl}/${c.id}`,
            op,
            model,
            ...c
        }
    }) : [];

    const [containerRef, containerDimensions] = useContainerDimensions([opened, technique, measure]);

    const topPadding = 20;
    const thumbnailSize = 56;
    const height = containerDimensions ? containerDimensions.height : 10;
    const numColumns = containerDimensions ? Math.floor((containerDimensions.width - 60) / thumbnailSize) : 1;
    const numRows = Math.ceil(channels.length / numColumns);

    let columnData: any = [];
    for (let r = 0; r < numRows; r++) {
        columnData[r] = channels.slice(r * numColumns, r * numColumns + numColumns)
    }
    columnData.thumbnailSize = thumbnailSize;

    type ChannelRowSidebarProps = {
        data: any,
        index: number,
        style: any
    }
    const ChannelRowSidebar = ({ data, index, style }: ChannelRowSidebarProps) => {
        const thumbnailSize = data.thumbnailSize;
        const channels = data[index];


        return (
            <>
                {
                    model && op ? (
                        <div
                            style={{
                                ...style, paddingTop: sidebarRowMargins.top, paddingBottom: sidebarRowMargins.bottom, top: style.top + topPadding
                            }}
                            className={styles.channelRow}
                        >
                            {
                                channels.map((channel: any) => {
                                    return (
                                        <Link key={channel.id} to={channel.url} style={{ width: thumbnailSize, height: thumbnailSize }} className={styles.channelIcon}>
                                            {technique && isUnitTechniqueType(technique) ? (<>
                                                <technique.Thumbnail model={model} op={op} unit={channel} />
                                                <span>{channel.title}</span>
                                            </>) : null}
                                        </Link>
                                    )
                                })
                            }

                        </div >

                    ) : null
                }
            </>
        );
    }
    // This is necessary to add some extra top padding to the virtual list:
    // https://github.com/bvaughn/react-window#can-i-add-padding-to-the-top-and-bottom-of-a-list
    const innerElementType = forwardRef(({ style, ...rest }: any, ref: any) => (
        <div
            ref={ref}
            style={{
                ...style,
                height: `${parseFloat(style.height) + topPadding * 2}px`
            }}
            {...rest}
        />
    ));

    return (
        <ul className={styles.channelListSidebar} ref={containerRef}>
            <List
                height={height}
                innerElementType={innerElementType}
                itemData={columnData}
                itemCount={columnData.length}
                itemSize={thumbnailSize + sidebarRowMargins.top + sidebarRowMargins.bottom}
                overscanCount={1}
            >
                {ChannelRowSidebar}
            </List>
        </ul>
    );


})


