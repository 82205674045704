import React, { useMemo, useState, useRef, useEffect } from 'react';

import { voronoi as d3Voronoi } from "d3-voronoi";

import useKeyPress from "../../../../hooks/useKeyPress";

import styles from "./OpDiagram.module.css";

import {
    Link,
} from "react-router-dom";


type OpHitAreaProps = {
    path: string,
    nodes: any[],
    extent: number[][],
    nodeWidth: number,
    nodeHeight: number,
    onHighlight: Function,
    onTooltip: Function,
    onClick?: Function
}
export default function OpHitArea({ path, nodes, extent, nodeWidth, nodeHeight, onHighlight, onTooltip, onClick }: OpHitAreaProps) {
    // const stageWidth = extent[1][0] - extent[0][0];
    const trunkPoints: any[] = []
    nodes.filter(n => n.depth === 0).forEach((n, i) => {

        const numTrunkSamples = Math.max(5, Math.round(2 + (n.op.cleanName.length) / 3));
        for (let s = 0; s < numTrunkSamples; s++) {
            trunkPoints.push([n.x * nodeWidth + s * 22 - 20, n.y * nodeHeight, n.i])
        }
    });
    const nonTrunkPoints = nodes.filter(n => n.depth !== 0).map((n, i) => [n.x * nodeWidth, n.y * nodeHeight, n.i])
    const points = trunkPoints.concat(nonTrunkPoints);


    // TODO: This is expensive and this useMemo doesn't seem to fix it.
    // Perhaps we should move it out of this component and into the data loading
    // stage.
    const voronoiPolygons = useMemo(() => {
        const voronoi = d3Voronoi()
        voronoi.extent(extent);

        const voronoiDiagram = voronoi(points);
        const voronoiPolygons = voronoiDiagram.polygons();
        return voronoiPolygons;

    }, [extent, points]);


    const escaped = useRef(false);
    const esc = useKeyPress("Escape");

    useEffect(() => {
        if (esc) {
            escaped.current = true;
            $tooltipActivated(false);
        }
    }, [esc]);

    const [tooltipActivated, $tooltipActivated] = useState(false);
    const stillnessTimer = useRef<any>();

    useEffect(() => {
        onTooltip(tooltipActivated);
    }, [tooltipActivated, onTooltip]);

    const onEnter = () => {
    }
    const onLeave = () => {
        $tooltipActivated(false);
        clearInterval(stillnessTimer.current);
        onHighlight();
    }
    const onNodeClick = (e: Event, opId: string) => {
        if (onClick) {
            onClick(e, opId)
        }
        escaped.current = true;
        $tooltipActivated(false);

    }
    const onNodeEnter = (id: string) => {
        escaped.current = false;
        onHighlight(id);
        if (!tooltipActivated && !escaped.current) {
            stillnessTimer.current = setInterval(
                () => stillnessAlarm(),
                1000
            );
        }
    }
    const onNodeLeave = () => {
        clearInterval(stillnessTimer.current);
        onHighlight();
    }
    const stillnessAlarm = () => {
        if (!escaped.current) {
            $tooltipActivated(true);
        }
    }

    return (
        <g
            className="voronoiHitAreas"
            onMouseEnter={() => onEnter()}
            onMouseLeave={() => onLeave()}
        >
            {
                voronoiPolygons.map((p: number[], i: number) => {
                    const node = nodes[points[i][2]]
                    const point = points[i];
                    return (
                        <Link key={node.op.id + i}
                            to={{
                                pathname: `${path}/${node.op.id}`
                            }}
                            onClick={(e: Event) => onNodeClick(e, node.op.id)}
                        >
                            <g
                                className={styles.hit}
                                onMouseEnter={() => onNodeEnter(node.op.id)}
                                onMouseLeave={() => onNodeLeave()}
                            >
                                <clipPath id={`voronoiClip-${i}`} >
                                    <circle cx={point[0]} cy={point[1]} r="25" />
                                </clipPath>
                                <path
                                    clipPath={`url(#voronoiClip-${i})`}
                                    d={"M" + p.join("L") + "Z"}
                                />
                            </g>
                        </Link>
                    )
                })
            }
        </g >
    );
}