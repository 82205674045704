import React from 'react';
import { useParams } from 'react-router-dom'
import Microscope from "./instruments/microscope/Microscope";
import Circuits from "./instruments/Circuits";
import ModelComparison from "./instruments/ModelComparison";
import InstrumentChooser from "./InstrumentChooser";
import { Error } from "./Alert";

// Instruments
const instruments = new Map([
    ['models', { 'name': 'Models', 'component': Microscope }],
    ['circuits', { 'name': 'Circuits', 'component': Circuits }],
    ['model_comparison', { 'name': 'Compare Models', 'component': ModelComparison }]
]);


export default function Instruments({ rootState }) {
    const { instrumentId } = useParams();

    const InstrumentComponent = instruments.has(instrumentId) ? instruments.get(instrumentId).component : null;

    return (
        <>
            {/* <InstrumentChooser instruments={instruments} instrumentId={instrumentId} /> */}
            {
                InstrumentComponent ?
                    <InstrumentComponent scopedState={rootState.subscope(instrumentId)} />
                    : <Error message={`Instrument “${instrumentId}” was not found.`} />
            }
        </>
    );
}