import React, {
    useState,
    useEffect
} from 'react';
import { Link } from "react-router-dom";
import styles from "./Panel.module.css";
import { observer } from "mobx-react";

import IconButton from "../../../../components/controls/IconButton";

type PanelProps = {
    title: string,
    main: any,
    opened: boolean,
    showSidebarWhenOpened?: boolean | undefined,
    openUrl?: string,
    closeUrl?: string,
    closedWidth?: number,
    onStateChange?: any,
    sidebar?: any,
    settings?: any,
}
const Panel = observer(({ title, sidebar, main, settings, opened, showSidebarWhenOpened, openUrl, closeUrl, closedWidth, onStateChange }: PanelProps) => {
    const [minimized, $minimized] = useState(false);

    const panelWidth = 240;
    closedWidth = closedWidth ? closedWidth : 240;

    useEffect(() => {
        if (opened) {
            $minimized(false);
        }
    }, [opened]);

    useEffect(() => {
        if (onStateChange) {
            onStateChange();
        }
    }, [minimized, opened])

    return (
        <div
            className={[styles.panel, opened ? styles.opened : styles.closed, minimized ? styles.minimized : styles.maximized].join(" ")}
            style={{ flexBasis: opened ? undefined : closedWidth }}
        >
            <div className={styles.head}>
                {opened ? (
                    <h2>{title}</h2>
                ) : (
                        <Link to={openUrl}><h2>{title}</h2></Link>
                    )}

                <div className={styles.controls}>
                    {minimized ? (
                        <IconButton icon="unminimize" onClick={() => $minimized(false)} />
                    ) : null}
                    {!minimized && !opened ? (
                        <>
                            <IconButton icon="minimize" onClick={() => $minimized(true)} />
                        </>
                    ) : null}
                    {opened ? (
                        <>
                            {/* <IconButton icon="expand" theme="dark" /> */}
                            {closeUrl ? <IconButton icon="close" theme="dark" url={closeUrl} /> : null}
                        </>
                    ) : (
                            null
                        )}

                </div>
            </div>
            <div className={[styles.body, opened ? styles.opened : null, showSidebarWhenOpened ? styles.showSidebarWhenOpened : null].join(" ")}>
                {settings ? <div className={styles.settings} style={{ flexBasis: panelWidth }}>{settings}</div> : null}
                <div className={styles.main}>{main}</div>
                <div className={styles.sidebar} style={{ flexBasis: (opened ? panelWidth : closedWidth) }}>{sidebar}</div>
            </div>
        </div>
    )
});

export default Panel;