import React, {
    useEffect, useState
} from 'react';

import { CaricatureEndpoints } from "../../../../endpoints.autogen";
import { observer } from "mobx-react";

import { opTechnique, techniqueTarget, opTechniqueRenderProps, paramDataType, chooserTypes } from "./Technique";
import { APICaricatureInterface } from '../stores/APIInterfaces';
import { Options } from '../stores/Store';


import { caricatureImages } from "./caricatureImages";

import textStyles from "../../../../components/TextStyles.module.css";
import styles from "./Caricature.module.css";

const imagesList: string[] = caricatureImages.map(c => c.title);

const caricatureParams = [
    { id: 'image', title: 'Input Image', options: new Options(imagesList, 2), dataType: paramDataType.string, suppressDisplay: [techniqueTarget.op], chooserType: chooserTypes.dropdown },
    { id: 'cossim_pow', title: 'Cossim Power', options: new Options(["0.0", "1.0", "2.0"], 2), dataType: paramDataType.string },
    //     { id: 'num_steps', title: 'Optimization Steps',options: new Options([512]), dataType: paramDataType.number},
]

const Thumbnail = observer(({ model, op, params }: opTechniqueRenderProps) => {
    const [imageUrl, $imageUrl] = useState<string | undefined>();
    // TODO: there is a race condition somewhere when this switches out...
    let image = caricatureParams[0].options.value;
    let cossim_pow = caricatureParams[1].options.value;
    useEffect(() => {
        $imageUrl(undefined);
        // const image = "books";
        const num_steps = 4096;
        // const cossim_pow = 0;
        (new CaricatureEndpoints()).op_caricature(model.id, op.id, image, num_steps, cossim_pow).then(function (result: APICaricatureInterface) {
            if (result) {
                $imageUrl(result.images[0].image.url);

            } else {
                $imageUrl(undefined);
            }
        });
    }, [model, op, image, cossim_pow]);
    return (<div><img src={imageUrl} alt="" /></div>)
})

const SingleImage = observer(({ model, op, image, title }: any) => {
    const [imageUrl, $imageUrl] = useState<string | undefined>();
    const [originalImageUrl, $originalImageUrl] = useState<string | undefined>();
    // let image = caricatureParams[0].options.value;
    let cossim_pow = caricatureParams[1].options.value;
    const num_steps = 4096;
    useEffect(() => {
        $imageUrl(undefined);
        (new CaricatureEndpoints()).op_caricature(model.id, op.id, image, num_steps, cossim_pow).then(function (result: APICaricatureInterface) {
            if (result) {
                $imageUrl(result.images[0].image.url);
                $originalImageUrl(result.images[0].original_image.url);

            } else {
                $imageUrl(undefined);
                $originalImageUrl(undefined);
            }
        }).catch(function (error) {
            console.log(`API Error for :${model.id}, ${op.id}, ${image}, ${num_steps}, ${cossim_pow}`)
            console.log(error)
        });
    }, [model.id, op.id, image, cossim_pow]);
    return (<div className={styles.singleImage} style={{}}>
        <div className={styles.outputImage}>
            <img src={imageUrl} alt="" />
        </div>
        <div className={styles.inputImage}>
            <img src={originalImageUrl} alt="" />
            <div className={textStyles.caption}>{title}</div>
        </div>
    </div>)
})

const Main = observer(({ model, op, params }: opTechniqueRenderProps) => {
    const [imageUrl, $imageUrl] = useState<string | undefined>();
    let paramTitle = caricatureParams[0].title
    let image = caricatureParams[0].options.value;
    let cossim_pow = caricatureParams[1].options.value;
    const num_steps = 4096;

    return (<div style={{ display: "flex", flexWrap: "wrap" }}>
        {caricatureParams[0].options.list.map((option) => {
            return <div>
                <div>
                    <SingleImage model={model} op={op} image={option.value} title={option.value} />
                </div>
            </div>
        })}

    </div>
    );
})


const Caricature: opTechnique = {
    id: "caricature",
    backendId: "lucid.caricature",
    title: "Caricature",
    rank: 2,
    Description: () => <>
        An artificial, optimized image that maximizes the activations in response to a real, given image.
    </>,
    display: {
        op: true,
        model: true,
        unit: false
    },
    op: true,
    params: caricatureParams,
    Thumbnail,
    Main,
}
export default Caricature;


// type CaricatureProps = {
//     scopedState: any,
//     technique: any,
// }
// const Caricature = observer(({ scopedState, technique }: CaricatureProps) => {
//     const { microscopeStore } = useStores();
//     const op = microscopeStore.op;
//     const model = microscopeStore.model;
//     const Component = technique ? technique.Component : null;
//     const [mutableParams, mutableParamsDispatch] = useTechniqueParams(AVAILABLE_TECHNIQUES, technique.id, scopedState);
//     const param = "image";
//     const options: any[] = mutableParams.paramMap.paramsDict.get(param).options;
//     return (
//         <div className={styles.caricature}>
//             {model && technique ? (
//                 <div>
//                     {options.map(option => {
//                         return (
//                             <div>
//                                 <TechniqueParamsWrapper techniques={AVAILABLE_TECHNIQUES} activeTechnique={technique.id} scopedState={scopedState}>
//                                     {(params: any) => {
//                                         params.set(param, option);
//                                         return (<>
//                                             <img src={`/caricature-${option}.jpg`} />
//                                             <Component intent={"thumbnail"} modelId={model.id} op={op} params={params} />
//                                         </>);
//                                     }}
//                                 </TechniqueParamsWrapper>
//                             </div>

//                         )
//                     })}
//                 </div>
//             ) : null}
//         </div>
//     )
// })


// const Caricature = observer(({ modelId, op, intent, params }) => {
//     const [leafData, $leafData] = useState(null);
//     const [isLoading, $isLoading] = useState(true);

//     useEffect(() => {
//         $isLoading(true);
//         const image = paramValue(Caricature.params, 'image', params);
//         const num_steps = paramValue(Caricature.params, 'num_steps', params);
//         const cossim_pow = paramValue(Caricature.params, 'cossim_pow', params);
//         (new CaricatureEndpoints()).op_caricature(modelId, op.id, image, num_steps, cossim_pow).then(function (result) {
//             if (result) {
//                 $leafData(result.images[0]);
//             } else {
//                 $leafData(null);  // not in cache
//             }
//             $isLoading(false);
//         });
//     }, [modelId, op, params]);

//     return (
//         <ThumbnailImage imageEntry={leafData} isLoading={isLoading} />
//     );
// });
