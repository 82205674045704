import React, {
    useEffect
} from 'react';
import { useStores } from "../stores/context";
import { observer } from "mobx-react";
import Panel from "./Panel";
import { DEFAULT_TECHNIQUES, isTechniqueInProgress } from "../Constants";
import { SyntheticTuningEndpoints } from "../../../../endpoints.autogen";
import styles from "./UnitPanel.module.css"
import textStyles from "../../../../components/TextStyles.module.css";
import useContainerDimensions from "../../../../hooks/useContainerDimensions";
import { InProgressBanner } from "../../../../components/Banners";
import { isUnitTechniqueType } from "../techniques/Technique"
import { Error } from "../../../Alert";

type UnitPanelProps = {
    baseUrl: string,
    opened: boolean,
}
const UnitPanel = observer(({ opened, baseUrl }: UnitPanelProps) => {
    const { microscopeStore, unitTechniqueStore } = useStores();
    const unit = microscopeStore.unit;
    const [containerRef, containerDimensions] = useContainerDimensions([opened, microscopeStore.unit]) as any;

    const urlParts = baseUrl.split("/");
    const closeUrl = urlParts.slice(0, -1).join("/");

    return (
        <>
            {unit ? (
                <Panel
                    opened={opened}
                    title={unit ? unit.title : ""}
                    closeUrl={closeUrl}
                    main={(
                        <div className={[styles.main, ((containerDimensions && containerDimensions.width < 900) ? styles.tightLayout : null)].join(" ")} ref={containerRef}>
                            {unitTechniqueStore.techniques.map((technique) => {
                                if (isUnitTechniqueType(technique)) {
                                    if (microscopeStore.model && microscopeStore.op && microscopeStore.unit && microscopeStore.op.techniques[technique.backendId]){
                                        return (
                                            <section>
                                                <div className={styles.sectionHeader}>
                                                    <div className={styles.headerWrapper}>
                                                        <h3>{technique.title}</h3>
                                                        <p><technique.Description /></p>
                                                        {technique.Footer ? (<p className={textStyles.note}><technique.Footer /></p>) : null}
                                                    </div>
                                                </div>
                                                <div className={styles.sectionContent}>
                                                    <technique.Main model={microscopeStore.model} op={microscopeStore.op} unit={microscopeStore.unit} />
                                                </div>
                                            </section>
                                        );
                                    }
                                } else {
                                    return <Error message={"Trying to render a non-unit technique type in the unit panel"} />
                                }

                            })}
                        </div>
                    )}
                />

            ) : null}
        </>
    )
});
export default UnitPanel;
