import {
    useState,
    useEffect,
    useRef, useLayoutEffect
} from "react";


type dimension = {
    width: number,
    height: number
}

export default function useContainerDimensions(inputs: any[]) {
    const [techniqueDimensions, $techniqueDimensions] = useState<dimension | undefined>();
    const techniqueContainerRef = useRef() as any;
    const measureTechniqueContainer = () => {
        let dimensions: dimension = { width: 0, height: 0 };
        if (techniqueContainerRef.current) {
            dimensions.width = techniqueContainerRef.current.offsetWidth;
            dimensions.height = techniqueContainerRef.current.offsetHeight;
        }
        $techniqueDimensions(dimensions);
    };
    useLayoutEffect(() => {
        measureTechniqueContainer()
        //eslint-disable-next-line
    }, inputs);
    useEffect(() => {
        window.addEventListener("resize", measureTechniqueContainer)
        return () => {
            window.removeEventListener("resize", measureTechniqueContainer)
        }
    });

    return [techniqueContainerRef, techniqueDimensions];
}

