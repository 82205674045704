import React from 'react';

export default function UnMinimize() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32">
            <polyline points="18.29 10.03 25.01 16.75 18.29 23.47" />
            <polyline points="9.29 10.03 16.01 16.75 9.29 23.47" />
        </svg>

    )
}