export type tuningFamily = {
    title: string,
    description: string
};

export const tuningFamilies: Map<string, tuningFamily> = new Map([
    ["color_hue_brightness_family",
        {
            title: "Hue/brightness",
            description: "Solid color swatches of varying hue and brightness."
        }],
    ["color_hue_saturation_family",
        {
            title: "Hue/saturation",
            description: "Solid color swatches of varying hue and saturation."
        }],
    ["color_center_family",
        {
            title: "Color center",
            description: "A color center with the opposite color in the background"
        }],
    ["color_contrast_family",
        {
            title: "Color contrast",
            description: "Divisions between opposite colors at different rotations."
        }],
    ["frequency_family",
        {
            title: "Frequency",
            description: "Different angles and frequencies of variations between black and white."
        }],
    ["frequency_family_r",
        {
            title: "Red/cyan Frequency ",
            description: "Different angles and frequencies of variations between red and cyan."
        }],
    ["frequency_family_g",
        {
            title: "Green/magenta Frequency",
            description: "Different angles and frequencies of variations between green and magenta."
        }],
    ["frequency_family_b",
        {
            title: "Blue/yellow frequency",
            description: "Different angles and frequencies of variations between blue and yellow."
        }],
    ["high_low_freq_family",
        {
            title: "High/low frequency",
            description: "Patterns to detect differences between high and low frequency boundaries."
        }],
    ["brightness_gradient_family_cos",
        {
            title: "Brightness gradient, cosine",
            description: "A gradient from black to white and to black again, at different orientations and amount of blur."
        }],
    ["brightness_gradient_family_sin",
        {
            title: "Brightness gradient, sine",
            description: "A gradient from white and to black, at different orientations and amount of blur"
        }],
    ["bw_transition_family",
        {
            title: "Black and white transition",
            description: "A hard black and white boundary, at different orientations and positions."
        }],
    ["b_line_family",
        {
            title: "White line",
            description: "White line on a black background at different orientations and positions."
        }],
]);