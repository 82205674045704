import JSON5 from 'json5';


const _global_cache = new Map();
const _global_fetch_requests = new Map();


export class EncyclopediaEndpointsClient {
    constructor(endpoint_status_url, endpoint_call_url) {
        this._endpoint_status_url = endpoint_call_url;
        this._endpoint_call_url = endpoint_call_url;

        // cache is first keyed by the endpoint call url and then by the call args
        if (!_global_cache.has(endpoint_call_url)) {
            _global_cache.set(endpoint_call_url, new Map());
        }
        if (!_global_fetch_requests.has(endpoint_call_url)) {
            _global_fetch_requests.set(endpoint_call_url, new Map());
        }
        this._cache = _global_cache.get(endpoint_call_url);
        this._fetch_requests = _global_fetch_requests.get(endpoint_call_url);

        this._csrf_token_promise = new Promise(function (resolve, reject) {
            resolve('no-token');
        });
        // this._csrf_token_promise = new Promise(function (resolve, reject) {
        //     const current_token = Cookies.get('csrftoken');
        //     if (current_token) {
        //         resolve(current_token);
        //     } else {
        //         fetch(endpoint_status_url)
        //             .then((response) => response.text())
        //             .then((responseText) => JSON5.parse(responseText))
        //             .then((responseJson) => {
        //                 const current_token = Cookies.get('csrftoken');
        //                 if (current_token) {
        //                     console.log('fetched csrf token', current_token);
        //                     resolve(current_token);
        //                 } else {
        //                     reject(new Error('csrf setup error: status page returned correctly but csrf cookie did not get set'));
        //                 }
        //             }).catch((error) => reject(error));
        //     }
        // });
        // this._csrf_token_promise.catch((error) => {
        //     console.error('csrf token resolution error', error);
        // });
    }

    _cache_key_from_args(call_args) {
        return JSON.stringify(call_args);
    }

    _set_cache_value(call_args, result) {
        const cache_key = this._cache_key_from_args(call_args);
        this._cache.set(cache_key, result);
    }

    _read_from_cache(call_args) {
        const cache_top_key = this._cache_key_from_args(call_args);
        if (this._cache.has(cache_top_key)) {
            return this._cache.get(cache_top_key);
        } else {
            return null;
        }
    }

    _call_endpoint(endpoint_class, constructor_args, endpoint_entry, endpoint_args, max_retries = 3) {
        const current_client = this;
        const call_args = [
            endpoint_class,
            Array.prototype.slice.call(constructor_args),
            endpoint_entry,
            Array.prototype.slice.call(endpoint_args)
        ];

        const p = new Promise(function (resolve, reject) {
            const cached_value = current_client._read_from_cache(call_args);
            if (cached_value) {
                resolve(cached_value['result']);
                return;
            }

            current_client._csrf_token_promise.then((csrfToken) => {
                const encoded_args = encodeURIComponent(JSON.stringify(call_args));
                const url = `${current_client._endpoint_call_url}:${endpoint_class}.${endpoint_entry}?args=${encoded_args}`;
                const cache_key = current_client._cache_key_from_args(call_args);

                function do_fetch() {
                    let fetch_promise = null;
                    if (current_client._fetch_requests.has(cache_key)) {
                        // console.log('using in flight fetch request');
                        fetch_promise = current_client._fetch_requests.get(cache_key);
                    } else {
                        console.log('calling endpoint with ', call_args);
                        fetch_promise = new Promise(function (fetch_resolve, fetch_reject) {
                            function do_fetch() {
                                var local_fetch_promise = fetch(url, {
                                    method: 'get',
                                    headers: {
                                        'Accept': 'application/json'
                                    }
                                }).then((response) => response.text())
                                    .then((responseText) => JSON5.parse(responseText))
                                    .then((responseJson) => {
                                        current_client._fetch_requests.delete(cache_key);
                                        if (responseJson['result'] || responseJson['error']) {
                                            current_client._set_cache_value(call_args, responseJson);
                                        }
                                        fetch_resolve(responseJson);
                                    });
                                return local_fetch_promise;
                            }

                            function fetch_with_retry(num_retries) {
                                do_fetch().catch((error) => {
                                    if (num_retries < max_retries) {
                                        const wait_time = num_retries * 5000 + Math.random() * 5000;
                                        console.error('caught fetch error, retrying in ' + wait_time + 'ms', error);
                                        setTimeout(function () {
                                            fetch_with_retry(num_retries + 1);
                                        }, wait_time);
                                    } else {
                                        console.error('max retries exceeded, rejecting bad fetch', error);
                                        current_client._fetch_requests.delete(cache_key);  // start fresh next time
                                        fetch_reject(error);
                                    }
                                });
                            }
                            fetch_with_retry(0);
                        });

                        current_client._fetch_requests.set(cache_key, fetch_promise);
                    }

                    fetch_promise
                        .then((responseJson) => {
                            // console.log('endpoint returned json', responseJson);
                            if (responseJson['error']) {
                                if (responseJson['cache_miss']) {
                                    console.log('cache miss for request: ', call_args);
                                    // resolve(null);
                                    reject(responseJson['cache_miss'])
                                } else {
                                    reject(responseJson['error'])
                                }
                            } else {
                                resolve(responseJson['result']);
                            }
                        })
                        .catch((error) => {
                            console.error('error calling endpoint', error);
                            // debugger; FIXME: we should probably add retries
                            reject(error);
                        });
                }

                do_fetch();

            }).catch((error) => {
                console.error('error getting csrf token', error);
                // debugger;
                reject(error);
            });


        });
        return p;
    }
}
