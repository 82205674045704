import React, {
    useEffect, useState
} from 'react';

import { observer } from "mobx-react";
import { unitTechnique, techniqueTarget, unitTechniqueRenderProps, paramDataType } from "./Technique";
import { NavLink as Link } from "react-router-dom";
import { TextFeatureVisEndpoints } from "../../../../endpoints.autogen";
import HeatMap from "../../../../components/Heatmap.jsx";


const Thumbnail = observer(({ model, op, unit, params }: unitTechniqueRenderProps) => {

    const [leafData, $leafData] = useState<any | undefined>();
    const [channelData, $channelData] = useState<any | undefined>();

    function set() {
        $leafData(undefined)
        $channelData(undefined)
        new TextFeatureVisEndpoints()
            .op_text_feature_vis(model.id, op.id)
            .then(function (result: any) {
                if (result) {
                    $channelData(result)
                    console.log("Channel ", result)
                    if (result.channels.length > 0) {
                        fetch(result.channels[unit.id]['text']['url'])
                        .then((response) => response.json())
                        .then( (responseJson) => {console.log("Response", responseJson); return $leafData(responseJson)} )
                    }
                } else {
                }
            });
    }

    useEffect(() => {
        if (model && op && unit) {
            set();
        }
    }, [model, op, unit, params]);
    
    if (channelData && leafData) {
        console.log("Channel Data", channelData)
        return <div style={{fontSize: "10px", color: "black", padding: "5px"}}> 
                {[...Array(4).keys()].map( (i) => <div style={{padding: "2px", textDecoration: "none"}}>{ parseFloat(leafData[leafData.length - i -1][0]).toFixed(2) } {leafData[leafData.length - i -1][1].slice(15)}</div>)}
               </div>
    } else {
        return <p></p>
    }
});

const Main = observer(({ model, op, unit, params }: unitTechniqueRenderProps) => {

    const [leafData, $leafData] = useState<any | undefined>();
    const [channelData, $channelData] = useState<any | undefined>();

    function set() {
        $leafData(undefined)
        $channelData(undefined)
        new TextFeatureVisEndpoints()
            .op_text_feature_vis(model.id, op.id)
            .then(function (result: any) {
                if (result) {
                    $channelData(result)
                    console.log("Channel ", result)
                    if (result.channels.length > 0) {
                        fetch(result.channels[unit.id]['text']['url'])
                        .then((response) => response.json())
                        .then( (responseJson) => {console.log("Response", responseJson); return $leafData(responseJson)} )
                    }
                } else {
                }
            });
    }

    useEffect(() => {
        if (model && op && unit) {
            set();
        }
    }, [model, op, unit, params]);
    
    if (channelData && leafData) {
        console.log("Channel Data", channelData)
        return <div> 
                {[...Array(leafData.length).keys()].map( (i) => <div>{ parseFloat(leafData[leafData.length - i -1][0]).toFixed(2) } {leafData[leafData.length - i -1][1].slice(15)}</div>)}
               </div>
    } else {
        return <p></p>
    }
});

const TextFeatureVis: unitTechnique = {
    id: "text_feature_vis",
    backendId: "lucid.text_feature_vis",
    title: "Text Feature Visualization",
    rank: -100,
    Description: () => <>
        Text that maximizes dot product with neuron.
    </>,
    unit: true,
    params: [

    ],
    display: {
        model: true,
        op: true,
        unit: true,
    },
    Thumbnail: Thumbnail,
    Main,
}

export default TextFeatureVis;
