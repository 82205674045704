import React, {
    useEffect, useState
} from 'react';
import { FeatureVisEndpoints } from "../../../../endpoints.autogen";
import { paramValue } from "../ParamsChooser";
import ThumbnailImage from "../../../../components/ThumbnailImage";
import { observer } from "mobx-react";
import { unitTechnique, techniqueTarget, unitTechniqueRenderProps, paramDataType } from "./Technique";
import { APIFeatureVisInterface } from "../stores/APIInterfaces"
import textStyles from "../../../../components/TextStyles.module.css";
import { Options } from '../stores/Store';
// import { DiversityFeatureVisEndpoints } from "../../../../endpoints.autogen";

const featurevisParams = [
    { id: 'type', title: 'Optimization Objective', options: new Options(['channel', 'neuron'], 0), dataType: paramDataType.string },
]

// This is the visible component for the thumbnail display of this technique.
const Thumbnail = observer(({ model, op, unit, params }: unitTechniqueRenderProps) => {
    const type = featurevisParams[0].options.value;
    const num_steps = 4*1024;

    const [imageUrl, $imageUrl] = useState<string | undefined>();

    useEffect(() => {
        $imageUrl(undefined);
        if (model && op && unit) {
            (new FeatureVisEndpoints().op_feature_vis(model.id, op.id, type, num_steps, 0))
                .then(function (result: APIFeatureVisInterface) {
                    if (result && result.channels && result.channels[unit.id] && result.channels[unit.id].images && result.channels[unit.id].images.length) {
                        $imageUrl(result.channels[unit.id].images[0].image.url);
                    } else {
                        $imageUrl(undefined);  // not in cache
                    }
                });
        }
    }, [model, op, unit, type]);

    return (<>
        {(imageUrl) ? (
            <img src={imageUrl} />
        ) : null}
    </>)
})

// This is the visible component for the main display of this technique.
const Main = observer(({ model, op, unit, params }: unitTechniqueRenderProps) => {
    const num_steps = 4*1024;

    const [channelUrl0, $channelUrl0] = useState<string | undefined>();
    const [neuronUrl0, $neuronUrl0] = useState<string | undefined>();

    const [channelUrl1, $channelUrl1] = useState<string | undefined>();
    const [neuronUrl1, $neuronUrl1] = useState<string | undefined>();

    const [channelUrl2, $channelUrl2] = useState<string | undefined>();
    const [neuronUrl2, $neuronUrl2] = useState<string | undefined>();

    const [diversityUrl, $diversityUrl] = useState<string[] | undefined>();


    useEffect(() => {
        $channelUrl2(undefined);
        $neuronUrl2(undefined);

        $channelUrl0(undefined);
        $neuronUrl0(undefined);

        $channelUrl1(undefined);
        $neuronUrl1(undefined);

        if (model && op && unit) {

            //Load channel data
            (new FeatureVisEndpoints().op_feature_vis(model.id, op.id, "channel", num_steps, 0))
                .then(function (result: APIFeatureVisInterface) {
                    if (result && result.channels && result.channels[unit.id] && result.channels[unit.id].images && result.channels[unit.id].images.length) {
                        $channelUrl2(result.channels[unit.id].images[0].image.url);
                    } else {
                        $channelUrl2(undefined);  // not in cache
                    }
                });

            (new FeatureVisEndpoints().op_feature_vis(model.id, op.id, "channel", num_steps, 1))
                .then(function (result: APIFeatureVisInterface) {
                    if (result && result.channels && result.channels[unit.id] && result.channels[unit.id].images && result.channels[unit.id].images.length) {
                        $channelUrl0(result.channels[unit.id].images[0].image.url);
                    } else {
                        $channelUrl0(undefined);  // not in cache
                    }
                });


            (new FeatureVisEndpoints().op_feature_vis(model.id, op.id, "channel", num_steps, 2))
                .then(function (result: APIFeatureVisInterface) {
                    if (result && result.channels && result.channels[unit.id] && result.channels[unit.id].images && result.channels[unit.id].images.length) {
                        $channelUrl1(result.channels[unit.id].images[0].image.url);
                    } else {
                        $channelUrl1(undefined);  // not in cache
                    }
                });

            // Load neuron data
            (new FeatureVisEndpoints().op_feature_vis(model.id, op.id, "neuron", num_steps, 0))
                .then(function (result: APIFeatureVisInterface) {
                    if (result && result.channels && result.channels[unit.id] && result.channels[unit.id].images && result.channels[unit.id].images.length) {
                        $neuronUrl2(result.channels[unit.id].images[0].image.url);
                    } else {
                        $neuronUrl2(undefined);  // not in cache
                    }
                });

            (new FeatureVisEndpoints().op_feature_vis(model.id, op.id, "neuron", num_steps, 1))
                .then(function (result: APIFeatureVisInterface) {
                    if (result && result.channels && result.channels[unit.id] && result.channels[unit.id].images && result.channels[unit.id].images.length) {
                        $neuronUrl0(result.channels[unit.id].images[0].image.url);
                    } else {
                        $neuronUrl0(undefined);  // not in cache
                    }
                });


            (new FeatureVisEndpoints().op_feature_vis(model.id, op.id, "neuron", num_steps, 2))
                .then(function (result: APIFeatureVisInterface) {
                    if (result && result.channels && result.channels[unit.id] && result.channels[unit.id].images && result.channels[unit.id].images.length) {
                        $neuronUrl1(result.channels[unit.id].images[0].image.url);
                    } else {
                        $neuronUrl1(undefined);  // not in cache
                    }
                });

        }

    }, [model, op, unit, params]);

    // const defaultX = 0
    // const [x, $x] = useState(defaultX);

    // const change = function () {
    //     $x(x + 1)
    // }
    // render
    return (
    <div>
        <div style={{ display: "flex" }} >
            <div style={{ flexBasis: 0, marginRight: 30 }}>
                <div style={{borderRadius: "8px"}}>
                    {(channelUrl0) ? (
                        <img src={channelUrl0} />
                    ) : null}
                </div>
                <div style={{borderRadius: "8px"}}>
                    {(channelUrl1) ? (
                        <img src={channelUrl1} />
                    ) : null}
                </div>
                <div style={{borderRadius: "8px"}}>
                    {(channelUrl2) ? (
                        <img src={channelUrl2} />
                    ) : null}
                </div>

                <div className={textStyles.caption}><strong>Channel optimization</strong> objective results in a repeating pattern.</div>
            </div>
            <div style={{ flexBasis: 0 }}>
                {(neuronUrl0) ? (
                    <img src={neuronUrl0} />
                ) : null}
                {(neuronUrl1) ? (
                    <img src={neuronUrl1} />
                ) : null}
                {(neuronUrl2) ? (
                    <img src={neuronUrl2} />
                ) : null}

                <div className={textStyles.caption}><strong>Neuron optimization</strong> objective shows spatial preferences.</div>
            </div>
        </div>

    </div>
    )
})

// This are the necessary details for a technique component
const FeatureVis: unitTechnique = {
    id: "feature_vis",
    backendId: "lucid.feature_vis",
    title: "Feature Visualization",
    rank: 10,
    Description: () => <>
        An artificial, optimized image that maximizes activations of the given unit. <a href="https://distill.pub/2017/feature-visualization/">Read more</a>.
    </>,
    unit: true,
    params: featurevisParams,
    display: {
        op: true,
        unit: true,
    },
    Thumbnail,
    Main,
}
export default FeatureVis;

// FeatureVis.techniqueType = 'channel';
// FeatureVis.thumbnailNaturalSize = { width: 224, height: 224 }
// FeatureVis.params = [
//     { id: 'type', label: 'Type', inputType: 'radio', options: ['channel', 'neuron'], default: 'channel' },
//     { id: 'num_steps', label: 'Optimization Steps', inputType: 'text', dataType: 'int', default: 1024, live: true }
// ];
// FeatureVis.Description = function () {
//     return <>
//         An artificial, optimized image that maximizes activations of the given unit. <a href="https://distill.pub/2017/feature-visualization/">Read more</a>.
//     </>
// }