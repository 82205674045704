import {EncyclopediaEndpointsClient} from './endpoints';


export class EncyclopediaGlobalState extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    model(model_id) {
        return this._call_endpoint('EncyclopediaGlobalState', this.constructor_args, 'model', arguments);
    }
    
    models() {
        return this._call_endpoint('EncyclopediaGlobalState', this.constructor_args, 'models', arguments);
    }
}


export class FeatureVisEndpoints extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    op_feature_vis(model_id, op_id, fv_type, num_steps, repeat) {
        return this._call_endpoint('FeatureVisEndpoints', this.constructor_args, 'op_feature_vis', arguments);
    }
}


export class ChannelExplorerEndpoints extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    channel_details(model_id, op_id, channel_id) {
        return this._call_endpoint('ChannelExplorerEndpoints', this.constructor_args, 'channel_details', arguments);
    }
    
    op_details(model_id, op_id, with_weights) {
        return this._call_endpoint('ChannelExplorerEndpoints', this.constructor_args, 'op_details', arguments);
    }
}


export class CaricatureEndpoints extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    op_caricature(model_id, op_id, image, num_steps, cossim_pow) {
        return this._call_endpoint('CaricatureEndpoints', this.constructor_args, 'op_caricature', arguments);
    }
}


export class DeepDreamEndpoints extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    op_deepdream(model_id, op_id, num_steps) {
        return this._call_endpoint('DeepDreamEndpoints', this.constructor_args, 'op_deepdream', arguments);
    }
}


export class SyntheticTuningEndpoints extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    op_synthetic_tuning(model_id, op_id, family) {
        return this._call_endpoint('SyntheticTuningEndpoints', this.constructor_args, 'op_synthetic_tuning', arguments);
    }
}
SyntheticTuningEndpoints.TUNING_FAMILIES = [["color_hue_brightness_family", "Hue/Brightness"], ["color_hue_saturation_family", "Hue/Saturation"], ["color_center_family", "Color Center"], ["color_contrast_family", "Color Contrast"], ["frequency_family", "Frequency"], ["frequency_family_r", "Frequency (Red)"], ["frequency_family_g", "Frequency (Green)"], ["frequency_family_b", "Frequency (Blue)"], ["high_low_freq_family", "Low Frequency"], ["brightness_gradient_family_cos", "Brightness (cos)"], ["brightness_gradient_family_sin", "Brightness (sin)"], ["bw_transition_family", "B/W Family"], ["b_line_family", "Line Angle"]];


export class DatasetExamplesEndpoints extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    op_dataset_example_crops(model_id, op_id, dataset_id) {
        return this._call_endpoint('DatasetExamplesEndpoints', this.constructor_args, 'op_dataset_example_crops', arguments);
    }
}


export class WeightsEndpoints extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    weights(model_id, op_id) {
        return this._call_endpoint('WeightsEndpoints', this.constructor_args, 'weights', arguments);
    }
}


export class TextFeatureVisEndpoints extends EncyclopediaEndpointsClient {
    constructor() {
        super('/api/status', '/api/encyclopedia_endpoints/call');
        this.constructor_args = arguments;
    }
    
    op_text_feature_vis(model_id, op_id) {
        return this._call_endpoint('TextFeatureVisEndpoints', this.constructor_args, 'op_text_feature_vis', arguments);
    }
}