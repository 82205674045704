import { observable, computed, action } from "mobx";

export enum LoadingStatus {
    pending,
    success,
    error,
    none
}

export type Option = {
    id?: string,
    value: any,
    label?: string,
}
function isOptionArray(tbd: Option[] | string[] | number[]): tbd is Option[] {
    if (tbd.length) {
        return (tbd[0] as Option).value !== undefined
    } else {
        return true;
    }
}

class ListItem {
    value: any
    label: string
    id: string
    constructor(value: any, label?: string, id?: string) {
        this.value = value;
        if (label !== undefined) {
            this.label = label
        } else {
            this.label = value + "";
        }
        if (id !== undefined) {
            this.id = id
        } else {
            this.id = this.label;
        }
    }
}

export class Options {
    constructor(list: Option[] | string[] | number[], defaultIndex?: number) {
        let items: ListItem[] = [];
        list.forEach((item: string | number | Option) => {
            if (typeof item === "string" || typeof item === "number") {
                items.push(new ListItem(item))
            } else {
                items.push(new ListItem(item.value, item.label, item.id));
            }
        });
        this.list = items;
        if (defaultIndex) {
            this.selectedIndex = defaultIndex;
        }
    }

    @observable
    list: ListItem[] = []

    @observable
    selectedIndex: number = 0;

    @action
    $selectedId(id: string) {
        let found = false;
        this.list.forEach((item, index) => {
            if (item.id === id) {
                this.$selectedIndex(index);
                found = true;
                return;
            }
        });
        if (!found) {
            throw new Error(`Can't find id ${id} in list: ${this.list.map(item => item.id)}`)
        }
    }

    @action
    $selectedIndex(index: number) {
        this.selectedIndex = index;
    }

    @computed
    get value(): any {
        return this.list[this.selectedIndex].value;
    }

    @computed
    get id(): string {
        return this.list[this.selectedIndex].id;
    }

    @computed
    get label(): string {
        return this.list[this.selectedIndex].label
    }
}