import React, {
} from 'react';

export type updateScrollPositionProps = {
    scrollTop: number,
    top: number,
    height: number,
}

type ScrollPaneProps = {
    contentHeight: number;
    height: number;
    onScroll?: Function;
    updateScrollPosition?: (input: updateScrollPositionProps) => void;
}

export default class ScrollPane extends React.Component<ScrollPaneProps> {
    constructor(props: ScrollPaneProps) {
        super(props);
        this.containerRef = React.createRef();
        this.state = {
        };
    }
    containerRef: any
    onScroll() {
        if (this.props.updateScrollPosition) {
            this.props.updateScrollPosition({
                scrollTop: this.containerRef.current.scrollTop,
                top: this.containerRef.current.scrollTop / this.props.contentHeight,
                height: this.props.height / this.props.contentHeight,
            });
        }
    }
    componentDidMount() {
        this.onScroll();
    }
    componentDidUpdate(prevProps: ScrollPaneProps) {
        if (
            this.props.contentHeight !== prevProps.contentHeight ||
            this.props.height !== prevProps.height
        ) {
            this.onScroll();
        }
    }
    render() {
        return (
            <div
                ref={this.containerRef}
                style={{ height: this.props.height, width: "100%", overflowY: "auto" }}
                onScroll={(e) => {
                    this.onScroll();
                }}
            >
                <div style={{ height: this.props.contentHeight, overflow: "hidden", width: "100%" }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}