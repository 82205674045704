export function StringifyMap(map) {
    return JSON.stringify(Object.fromEntries(map.entries()));
}

export const numerals = function (n) {
    const s = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "10",
        "11",
        "a dozen",
    ]
    if (n > 12) {
        return n + '';
    } else if (n > 0) {
        return s[n];
    } else {
        return n + '';
    }
}