import React, {
    useEffect
} from 'react';

export function Error({ message }) {
    console.error("!!Alert!!", message);

    useEffect(() => {
        document.title = `OpenAI Microscope / Error — ${message}`;
    }, [message]);

    return (
        <div style={{ padding: "var(--side-margin)" }}>
            <h2>Error</h2>
            <p>{message}</p>
        </div>
    );
}