import React, {
    useEffect, useState, ReactEventHandler
} from 'react';

import { observer } from "mobx-react";
import { unitTechnique, techniqueTarget, unitTechniqueRenderProps } from "./Technique";
import textStyles from "../../../../components/TextStyles.module.css";
import styles from "./SyntheticTuning.module.css";
import { Model } from "../stores/ModelStore";
import { Op } from "../stores/OpStore";
import { Unit } from "../stores/UnitStore";
import { SyntheticTuningEndpoints } from "../../../../endpoints.autogen";
import { APISyntheticTuningInterface } from "../stores/APIInterfaces";
import { tuningFamilies, tuningFamily } from "./syntheticTuningData";

// SyntheticTuning.params = [
//     { id: 'family', label: 'Image Family', inputType: 'radio', options: SyntheticTuningEndpoints.TUNING_FAMILIES, default: SyntheticTuningEndpoints.TUNING_FAMILIES[0][0] }
// ];

const Thumbnail = observer(({ model, op, unit, params }: unitTechniqueRenderProps) => {
    return <>N/A</>;
});


type OneFamilyParams = {
    model: Model,
    op: Op,
    unit: Unit,
    familyId: string,
    family: tuningFamily
}

const OneFamily = observer(({ model, op, unit, family, familyId }: OneFamilyParams) => {

    const [leafData, $leafData] = useState<string | undefined>();
    const [exampleData, $exampleData] = useState<string | undefined>();
    // const [isLoading, $isLoading] = useState(true);

    // const family = paramValue(SyntheticTuning.params, 'family', params);
    // const metadata = familyTitles.get(family);

    useEffect(() => {
        $leafData(undefined);
        $exampleData(undefined);
        (new SyntheticTuningEndpoints().op_synthetic_tuning(model.id, op.id, familyId)).then(function (result: APISyntheticTuningInterface) {
            if (result) {
                const d = result.channels[unit.id].images[0].image.url;
                $leafData(d);
                if (result.family) {
                    $exampleData(result.family.url);
                } else {
                    $leafData(undefined);  // not in cache
                }
            }
        });
    }, [model, op, family]);


    const defaultX = 8;
    const defaultY = 1
    const [x, $x] = useState(defaultX);
    const [y, $y] = useState(defaultY);


    const fullImageSize = 200;
    const gridSize = 30;
    const loupeSize = 50;
    const resizedImageSize = gridSize * loupeSize;
    const highlightSize = fullImageSize / gridSize;

    function onMouseMove(e: React.MouseEvent) {
        $x(Math.max(0, Math.floor(e.nativeEvent.offsetX / fullImageSize * gridSize)));
        $y(Math.max(0, Math.floor(e.nativeEvent.offsetY / fullImageSize * gridSize)));
    }
    function resetHover() {
        $x(defaultX);
        $y(defaultY);
    }

    return (<>
        <div className={styles.intro}>
            <div className={textStyles.caption} style={{ margin: 0 }}>
                <strong>{family.title}</strong><br />
                {family.description}
            </div>
            <div className={styles.loupe} style={{ width: loupeSize, height: loupeSize }}>
                <img src={exampleData} style={{ top: -y * loupeSize, left: -x * loupeSize, width: resizedImageSize, height: resizedImageSize }} />
            </div>
        </div>
        <div
            className={styles.imageWrapper}
            onMouseMove={(e) => { onMouseMove(e) }}
            onMouseOut={resetHover}
        >
            <img src={exampleData} />
            <div className={styles.highlight} style={{ top: y * highlightSize, left: x * highlightSize, width: highlightSize, height: highlightSize }}></div>
        </div>

        <div
            className={styles.imageWrapper}
            onMouseMove={(e: React.MouseEvent) => { onMouseMove(e) }}
            onMouseOut={resetHover}
        >
            <img src={leafData} />
            {/* <ThumbnailImage imageEntry={leafData} isLoading={isLoading} style={style} /> */}
            <div className={styles.highlight} style={{ top: y * highlightSize, left: x * highlightSize, width: highlightSize, height: highlightSize }}></div>
        </div>
    </>);
});

const Main = observer(({ model, op, unit, params }: unitTechniqueRenderProps) => {
    // const collected = op ? op.techniques[component.backendId] : false;
    // const inProgress = modelId && op ? isTechniqueInProgress(modelId, op.id, component.backendId) : false;

    return <>
        <div className={[styles.syntheticTuningHeaderRow, styles.sticky].join(" ")}>
            <div>Family</div>
            <div>All Inputs</div>
            <div>Magnitude of Responses</div>
        </div>
        {[...tuningFamilies.entries()].map(tf => {
            const familyId = tf[0];
            return (
                <div key={familyId} className={styles.syntheticTuningRow}>
                    <OneFamily familyId={familyId} family={tf[1]} model={model} op={op} unit={unit} />
                </div>
            )
        })}

    </>;
});

const SyntheticTuning: unitTechnique = {
    id: "synthetic_tuning",
    backendId: "lucid.synthetic_tuning",
    title: "Synthetic Tuning",
    Description: () => <>
        Test patterns that show how strongly a unit reacts to specific, artificial inputs.
    </>,
    unit: true,
    params: [

    ],
    display: {
        unit: true
    },
    Thumbnail,
    Main,
}
export default SyntheticTuning;
