import React from 'react';
import {
    NavLink,
} from "react-router-dom";
import styles from "./Nav.module.css";
import LogoHex from "./LogoHex";

export default function Nav(props) {
    const { children } = props

    return (
        <nav className={styles.root}>
            <NavLink exact to="/" className={styles.logoIcon}>
                <LogoHex color="white" />
                <div className={styles.logoText}>Microscope</div>
            </NavLink>
            <div className={styles.links}>
                {children}
            </div>
        </nav>
    );
}