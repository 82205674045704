import React from 'react';

export default function Minimize() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32">
            <polyline points="14.29 23.47 7.57 16.75 14.29 10.03" />
            <polyline points="23.29 23.47 16.57 16.75 23.29 10.03" />
        </svg>

    );
}