import React, {
    useState
} from 'react';
import { observer } from "mobx-react"
import { Options } from "../../pages/instruments/microscope/stores/Store"
import { chooserTypes } from "../../pages/instruments/microscope/techniques/Technique";

type ListChooserProps = {
    options: Options,
    type?: chooserTypes,
    style?: object,
}
const ListChooser = observer(({ options, type, style }: ListChooserProps) => {
    const chooserType = type ? type : "radio";

    if (chooserType === chooserTypes.radio) {
        return (
            <div style={{ display: "grid", gridGap: 2, ...style }}>
                {options.list.map((option, index) => {
                    return (
                        <label key={option.id} style={{ fontSize: 12, cursor: "pointer" }}>
                            <input
                                style={{ marginRight: 8 }}
                                type="radio"
                                checked={options.selectedIndex === index}
                                onChange={() => options.$selectedIndex(index)}
                            />
                            {option.label}
                        </label>
                    );
                })}
            </div>
        );
    }
    else if (chooserType === chooserTypes.dropdown) {
        return (
            <select
                value={options.list[options.selectedIndex].value}
                onChange={(event) => {
                    const index = options.list.findIndex(({ value, label }) => value == event.target.value)
                    options.$selectedIndex(index);
                }}
                style={{ width: "100%", ...style }}>
                {options.list.map(option =>
                    <option key={option.id} value={option.value}>{option.label}</option>
                )}
            </select>
        );
    }
    else if (chooserType === chooserTypes.slider) {
        return (
            <input
                style={{ width: "100%", ...style }}
                type="range"
                value={options.selectedIndex}
                min="0"
                max={options.list.length - 1}
                onChange={(event) => options.$selectedIndex(parseInt(event.target.value, 10))}
            />
        );
    }
    else {
        console.error(`Invalid type of ${type} given to ListChooser.`);
        return null;
    }
})
export default ListChooser;