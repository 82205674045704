import React, {
    useState,
    useEffect
} from 'react';

import { useStores } from "../stores/context";
import { observer } from "mobx-react";

import { NavLink as Link } from "react-router-dom";
import Panel from "./Panel";
import { DEFAULT_TECHNIQUES } from "../Constants";
import styles from "./MicroscopePanel.module.css";
import useGraphMetadata from "../../../../hooks/useGraphMetadata";
import { Model, Layout } from "../stores/ModelStore";
// prune these

import cardStyles from "./MicroscopeSummary.module.css";
import opDiagramStyles from "../diagrams/OpDiagram.module.css";
import OpIcon from "../diagrams/OpIcon";
import OpEdge from "../diagrams/OpEdge";

import { opTechniques } from "../techniques/techniques";
import { technique, opTechnique } from "../techniques/Technique";
import { Op } from "../stores/OpStore"

import { numerals } from "../../../../helpers/utils";

type MicroscopePanelProps = {
    baseUrl: string,
    opened: boolean,
}
const MicroscopePanel = observer(({ baseUrl, opened }: MicroscopePanelProps) => {
    const { microscopeStore } = useStores();
    return (
        <Panel
            title={"Models"}
            opened={opened}
            openUrl={baseUrl}
            onStateChange={() => { microscopeStore.$measure(Date.now()) }}
            sidebar={<MicroscropeSidebar baseUrl={baseUrl} />}
            main={<MicroscopeMain baseUrl={baseUrl} opened={opened} />}
        />
    );
});
export default MicroscopePanel;

type MicroscopeSidebarProps = {
    baseUrl: string,
}
const MicroscropeSidebar = observer(({ baseUrl }: MicroscopeSidebarProps) => {
    const { microscopeStore } = useStores();
    return (
        <ul className={styles.modelList}>
            {microscopeStore.models.map(model => {
                return (
                    <li key={model.id}>
                        <Link className={styles.modelListLink} to={`${baseUrl}/${model.id}`}>
                            <div className={styles.label}>
                                <h5>{model.title}</h5>
                            </div>
                        </Link>
                    </li>
                )
            })}
        </ul>
    );
});

type MicroscopeMainProps = {
    baseUrl: string,
    opened: boolean,
}
const MicroscopeMain = observer(({ baseUrl, opened }: MicroscopeMainProps) => {
    const { microscopeStore } = useStores();
    const technique = opTechniques[0];
    return (
        <div className={styles.main}>
            {opened ? (
                <>
                    <div className={styles.kicker}>
                        <div>
                            The OpenAI Microscope is a collection of
                            visualizations of every significant layer and neuron
                             of {microscopeStore.models.length ? numerals(microscopeStore.models.length) : ''}
                             important vision models. <Link to={"/about"}>learn more</Link>
                        </div>
                    </div>
                    {microscopeStore.models.map(model => (
                        <Link key={model.id} className={styles.modelCardLink} to={`${baseUrl}/${model.id}`}>
                            <ModelCard model={model} technique={technique} />
                        </Link>
                    ))}
                </>
            ) : null}
        </div>
    );
});

type ModelCardProps = {
    model: Model,
    technique: opTechnique,
}
const ModelCard = observer(({ model, technique }: ModelCardProps) => {
    const modelGraph = model.layout;

    const [ops, $ops] = useState([]) as any;

    useEffect(() => {
        if (modelGraph) {
            let goodOps = modelGraph.nodes;
            goodOps = goodOps.filter(n => !(n.op.type === "Placeholder" && n.op.name.includes("nput")));
            // goodOps = goodOps.filter(n => n.depth === 1 );
            goodOps = goodOps.filter(n => n.op.type !== "Softmax");
            goodOps = goodOps.filter(n => n.op.type !== "Split");
            goodOps = goodOps.filter(n => !n.op.type.endsWith("ool"));
            $ops(goodOps.slice(1, 6));
        }
    }, [modelGraph])

    return (
        <div className={[styles.modelCard, "shadowed-heavy"].join(" ")}>
            <div className={styles.header}>
                <h3>{model.title}</h3>
                <div>{model.description}</div>
            </div>
            <div className={styles.details}>
                <div className={styles.diagram}>
                    {modelGraph ? (
                        <>
                            <div className={styles.size}>
                                {modelGraph.nodes.length} nodes
                            </div>
                            <ModelMiniDiagram graph={modelGraph} />
                        </>
                    ) : null}
                </div>
                <div className={styles.techniques}>
                    {ops.length ? ops.map((op: Op, i: number) => {
                        return (<div key={op.id} style={{ zIndex: ops.length - i, top: i * 30 - 15 }}>
                            <technique.Thumbnail model={model} op={op} />
                        </div>)

                    }
                    ) : null}
                </div>
            </div>
        </div>
    )
});

type ModelMiniDiagramProps = {
    graph: Layout
}

function ModelMiniDiagram({ graph }: ModelMiniDiagramProps) {
    const stageWidth = 200;
    const opWidth = 12;
    const opHeight = 8;
    const opMarginX = 3;
    const opMarginY = 3;
    const groupLayoutMargin = 0.5;
    const stageMarginLeft = opWidth + 2;
    const stageMarginTop = opHeight / 2 + opMarginY;
    const groupMargin = 4;
    const { nodes, edges, groups, tree } = graph;
    return (
        <div className={opDiagramStyles.root} >
            <svg className={opDiagramStyles.opLayout} width={stageWidth} height={tree.layoutHeight ? tree.layoutHeight * (opHeight + opMarginY) + 2 * opMarginY : 0} >
                <g transform={`translate(${stageMarginLeft}, ${stageMarginTop})`}>

                    {/* Groups */}
                    <g className="groups">
                        {groups.map((group: any) => (
                            <g
                                key={group.id}
                                transform={`
                                translate(${((group.x) * (opWidth + opMarginX) - opWidth / 2 - groupMargin)}, ${group.y * (opHeight + opMarginY) - opHeight / 2 - groupMargin})
                            `}
                                className={opDiagramStyles.group}
                            >
                                <rect
                                    rx={opWidth / 4 + groupMargin}
                                    width={group.layoutWidth * (opWidth + opMarginX) - opMarginX + groupMargin * 2}
                                    height={(group.layoutHeight - groupLayoutMargin) * (opHeight + opMarginY) - opMarginY + groupMargin * 2}
                                />
                            </g>
                        ))}
                    </g>

                    {/* Edges */}
                    <g className="edges">
                        {edges.map((edge: any) => (
                            <g key={edge.id} className={cardStyles.edge}>
                                <OpEdge
                                    sx={edge.start.x * (opWidth + opMarginX)}
                                    sy={edge.start.y * (opHeight + opMarginY)}
                                    ex={edge.end.x * (opWidth + opMarginX)}
                                    ey={edge.end.y * (opHeight + opMarginY)}
                                />
                            </g>
                        ))}
                    </g>

                    {/* Nodes */}
                    <g className="nodes">
                        {nodes.map((node: any) => (
                            <g
                                key={node.id}
                                transform={`translate(${(node.x * (opWidth + opMarginX))}, ${node.y * (opHeight + opMarginY)})`}
                                className={cardStyles.node}
                            >
                                <OpIcon type={node.op.type} op={node.op} width={opWidth} height={opHeight} suppressLabels={true} />
                            </g>
                        ))}
                    </g>
                </g>
            </svg>
        </div>
    )
}
